@import "../../../style/index";

.container {
  max-width: 1310px;
  margin: 0 auto;
  padding: 0 16px;
  box-sizing: border-box;

  @media (min-width: 768px) and (max-width: 1450px) {
    margin-left: 70px; } }

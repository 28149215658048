@import "../../style/index";

.emails, .email-modal {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: var(--app-font-family);
    font-size: 16px;
    color: $color-ship-gray; }

  .status-label {
    color: $color-ship-gray;
    border-radius: 40px;
    height: 28px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;

    &--width-auto {
      width: auto; }

    &--delivered, &--open, &--click {
      background-color: $color-minty-green; }
    &--bounce, &--processed {
      background-color: $color-creamy-vanilla; }
    &--other {
      background-color: $color-rosewater-pink; } }

  &__searchPanel {
    column-gap: 2rem !important;
    margin-bottom: 20px !important;
    &__select {
      max-width: 160px; } }

  &__bulk-change {
    height: 44px;
    display: flex;
    align-items: center;
    column-gap: 2rem;
    margin-bottom: 20px;

    .checkbox {
      width: 256px; }

    .buttons-block {
      padding: 0 !important; }

    & > * {
      user-select: none; } }

  .checkbox {
    margin-bottom: 0;

    label:before {
      margin-top: 0; } }

  &__button {
    padding: 0 20px; }

  &__open-email-button {
    border: none;
    margin-left: 5px;
    background: none;
    letter-spacing: -0.44px;
    text-decoration: underline;
    font-family: var(--app-font-family);
    font-size: 16px;
    color: $color-text-dark;
    padding: 0;

    &:hover {
      cursor: pointer; } }

  &__resend-button {
    padding: 0 20px; }

  &__modal-loader {
    height: 300px;
    position: relative; }

  &__status {
    .custom-select__option, .custom-select__single-value {
      text-transform: capitalize; } }

  .ReactTable {
    height: calc(100vh - 260px) !important;
    .checkbox {
      margin: 0; }

    .rt-thead .rt-tr {
      height: 65px; }

    .rt-tbody .rt-tr-group .rt-td {
      &:first-child {
        .checkbox {
          label::before {
            margin-right: 0; } } }

      &:last-child {
        background: transparent;
        cursor: pointer; } } } }

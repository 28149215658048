@import "../../../style/index";

.custom-tooltip {
    position: fixed;
    background-color: $color-light;
    color: $color-ship-gray;
    padding: 8px;
    border-radius: 4px;
    box-shadow: $toolip-shadow;
    z-index: 9999; }

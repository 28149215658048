@import "../../style/index";

@mixin getSidesPadding() {
    padding-left: 48px;
    padding-right: 48px;
    @include respond-to('small-max') {
        padding-left: 20px;
        padding-right: 20px; } }

.reselling-request {
    font-family: var(--app-font-family);
    border: 1px solid $color-seashell;
    border-radius: 12px;
    padding: 0;
    color: $color-text-dark;
    @media (min-width: 768px) and (max-width: 1450px) {
        margin-left: auto; }
    &__wrapper {
        padding: 60px 16px;
        background-color: $color-light;
        @include respond-to('small-max') {
            padding: 0 16px 30px 16px; } }
    &__logo {
        padding: 25px 0;
        display: flex;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        justify-content: center;
        align-items: center;
        background-color: var(--primary-light-color);
        img {
            height: 46px;
            @include respond-to('small-max') {
                height: 36px; } } }
    &__header {
        padding: 60px;
        @include getSidesPadding();
        color: $color-light;
        &-category {
            margin: 0;
            font-size: 14px;
            font-weight: 700; }
        &-title {
            margin: 0;
            line-height: 45px;
            font-size: 36px;
            font-weight: 700;
            @include respond-to('small-max') {
                font-size: 24px;
                line-height: normal; } }
        &-subtitle {
            margin: 0;
            font-size: 14px;
            font-weight: 400; }
        &-location {
            margin-top: 50px;
            @include respond-to('small-max') {
                margin-top: 30px; }
            display: flex;
            align-items: center;
            p {
                margin: 0;
                margin-left: 5px;
                font-size: 14px;
                font-weight: 500; } } }
    &__hero {
        // height: 550px
        // overflow-y: auto
        padding: 32px 0 16px 0;
        @include getSidesPadding();
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 25px;
        background-color: $color-light;
        @include respond-to('small-max') {
            grid-template-columns: 1fr; } }
    &__coins {
        padding: 32px 0 16px 0;
        @include getSidesPadding();
        background-color: $color-light;
        span {
            font-size: 16px;
            font-weight: 400; } }
    &__footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        padding: 32px 0 16px 0;
        @include getSidesPadding();
        border-top: 1px solid $color-seashell;
        background-color: $color-light;
        @include respond-to('small-max') {
            justify-content: center; }
        button {
            font-family: var(--app-font-family);
            width: 150px; } }
    &__modal {
        h3, p, h2, div, button {
            font-family: var(--app-font-family) !important; }
        &_confirm {
            padding: 20px;
            .swal2-content {
                padding: 48px 0; } }
        &_reselling-closed {
            padding: 20px; } } }

.blocked-user-modal {
  width: 416px;

  .swal2-title {
    margin-bottom: 30px; }

  .swal2-content {
    p {
      margin-bottom: 0; } }

  img {
    width: 320px;
    height: 241px; } }

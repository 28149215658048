@import "../../../../style/index.sass";

.actions-menu {
    position: fixed;
    z-index: 999;
    font-family: var(--app-font-family);
    border-radius: 5px;
    background: $color-light;
    box-shadow: 0px 1px 10px 0px #5F5C601F;

    &.hide {
        display: none; }

    .actions-menu-item {
        padding: 6px 12px;
        font-size: 18px;
        color: $color-ship-gray;
        cursor: pointer;
        &:hover {
            background: var(--primary-light-color); } }

    .menu-item-red {
        color: var(--reject-button-color); } }

.points-modal {
  &__title {
    font-size: 18px;
    text-align: center;
    font-weight: 700;
    margin-bottom: 40px; }

  &__radio-wrapper {
    display: flex;
    gap: 40px;
    justify-content: center;
    margin-bottom: 30px; }

  &__points-input {
    width: 320px;
    margin-bottom: 30px; }

  &__info-list {
    margin: 0 auto 50px;
    width: 320px;
    text-align: left;
    font-size: 16px; }

  &__info-item {
    margin-bottom: 8px; }

  &__buttons {
    display: flex;
    gap: 10px;
    justify-content: center; }

  &__button {
    min-width: 170px; } }

.points-modal-loader {
  position: relative;
  height: 286px; }

@import "../../style/index.sass";

.Upload {
  box-sizing: border-box;
  width: 641px;
  border: 1px dashed $color-gray;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.upload-over {
    border: 1px dashed var(--primary-color);
    background: rgba(216,216,216,0.19); }

  .Content {
    width: 100%;
    position: relative;

    .Files {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $color-light;

      .Row {
        position: relative;
        text-align: center;

        .img-wrapper {
          width: 71px;
          height: 85px;
          position: relative;
          margin: 0 auto; } }

      img.uploaded-file {
        height: 85px;
        width: 71px; }

      img.close {
        height: 28px;
        width: 28px;
        position: absolute;
        left: -13px;
        top: -13px;
        cursor: pointer; }

      span {
        display: block;
        color: $color-ship-gray;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: -0.35px;
        margin-top: 15px; } } }

  & p {
    color: $color-gray;
    font-size: 20px;
    margin-top: 0;
    text-align: center;
    margin-bottom: 13px; } }

.only-one-file-modal,
.unsupported-file-format-modal {
  p {
    font-size: 20px;
    margin-bottom: 10px;

    strong {
      display: block;
      margin-bottom: 20px; } } }

@import "../variables";

.custom-select {
    box-sizing: border-box;
    font-family: var(--app-font-family);
    input {
        height: auto; }
    &__indicator-separator {
        visibility: hidden; }
    &__control {
        height: 46px;
        border: 1px solid $color-seashell !important;
        &:hover {
            border-color: var(--primary-semi-light-color) !important;
            cursor: pointer; }
        &--is-focused.custom-select__control--is-focused {
            border-color: var(--primary-color) !important;
            box-shadow: none !important;
            position: relative;
            z-index: 4; } }

    &__indicators {
        height: 100%; }

    &__value-container {
        height: 100%; }

    &__menu {
      z-index: 4 !important; }

    &__loading-indicator {
        span {
            font-size: 4px; } }
    &__option {
        &--is-focused {
            background: var(--primary-light-color) !important; }
        &--is-selected {
            background-color: var(--primary-color) !important; } } }

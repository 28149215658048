.Toastify {
  &:hover {
    cursor: auto; }

  &__toast-container {
    width: 418px;
    height: 60px;
    padding: 0;

    top: -10px;

    @media (max-width: 767px) {
      width: 288px;
      left: 50%; }

    &--top-center {
      margin-left: 0;
      transform: translateX(-50%); } }

  &__toast {
    cursor: default;

    &--success {
      background-color: $color-minty-green;
      box-shadow: none;
      border-radius: 10px; }

    &--info {
      background-color: $color-light-gray;
      box-shadow: none;
      border-radius: 10px; }

    &--warning {
      box-shadow: none;
      border-radius: 10px; }

    &--error {
      background-color: $color-rosewater-pink;
      box-shadow: none;
      border-radius: 10px; } }

  &__toast-body {
    text-align: center;
    font-family: var(--app-font-family);
    font-weight: 600;
    font-size: 18px;
    color: $color-text-dark; } }

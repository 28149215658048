@import "../../style/index";

.sidebar {
    width: 198px;
    height: 100vh;
    padding-top: 44px;

    z-index: 100;

    position: fixed;
    top: 0;
    left: 0;

    background-color: $color-light;
    border-right: 1px solid $color-seashell;

    transition: .3s linear;

    &.minimized {
        width: 60px;

        .sidebar__items-wrapper {
            @media only screen and (min-device-width : 375px) and (max-device-width : 812px) and (-webkit-device-pixel-ratio : 3) and (orientation : landscape) {
                display: none; } }

        .sidebar-item {
            span {
                opacity: 0;
                visibility: hidden; } }

        @media (max-width: 812px) and (orientation: landscape) {
            overflow: hidden; } }

    @include respond-to('small-max') {
        width: 100%;
        padding-top: 16px;

        border-right: none;

        &.minimized {
            height: 40px; }

        &__items-wrapper {
            margin-top: 30px !important;
            height: 40px;

            .sidebar-item {
                &:last-child {
                    margin-top: 4rem !important; } } }

        &.minimized {
            width: 58px !important;

            background-color: transparent;

            .sidebar-item {
                visibility: hidden; } } }

    @media (max-width: 812px) and (orientation: landscape) {
        overflow: hidden scroll; }

    .btn-burger {
        margin-left: 18px; }

    &__items-wrapper {
        margin-top: 64px;

        .sidebar-item {
            height: 40px;
            padding-left: 16px;
            margin-bottom: 40px;

            display: flex;
            flex-direction: row;
            align-items: center;

            border-left: 2px solid transparent;

            text-decoration: none;
            white-space: nowrap;
            font-family: var(--app-font-family);
            font-size: 18px;
            color: $color-ship-gray;

            &:hover {
                cursor: pointer; }

            @include respond-to('small-max') {
                margin-bottom: 20px; }

            &:last-child {
                margin-top: 8rem; }

            svg {
                margin-right: 20px;
                position: absolute;
                g {
                    fill: $color-ship-gray; } }

            span {
                margin-left: 44px;

                visibility: visible;
                opacity: 1;

                transition: .2s linear; }

            &:hover {
              color: var(--secondary-color);
              svg {
                  g {
                      fill: var(--secondary-color); } }
              span {
                color:  var(--secondary-color); } }

            &.active {
                border-left: 2px solid var(--secondary-color);

                color: var(--primary-color);

                svg {
                    g {
                        fill: var(--secondary-color); } }

                span {
                  color:  var(--secondary-color); } } } } }


@import "../../../../../style/index";

.email {
  color: $color-text-dark;
  font-family: var(--app-font-family);
  position: relative;
  flex-grow: 1;

  button {
    svg {
      display: initial; } }

  .page-title {
    margin: 36px 0; }

  &__block {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    position: relative;

    label {
      color: #ABABAB;
      font-size: 16px;
      letter-spacing: -0.44px;
      line-height: 22px;
      margin-bottom: 10px; }

    input {
      width: 50%; } }

  &__select {
    width: 50%;

    span {
      background-color: transparent; } }

  &__textfield {
    height: 46px;
    box-sizing: border-box;
    border-radius: 4px;

    &--is-error {
      border-color: red; } }

  .Dropzone {
    border-radius: 12px;
    border: 2px solid $color-seashell;
    padding: 0;
    margin-bottom: 20px;
    overflow: hidden;
    height: 260px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    align-items: center;
    justify-content: center; }

  &__content-block {
    margin-bottom: 40px; }

  .rsw-ce {
    height: 400px;
    overflow: auto; }

  .rsw-dd {
    margin-right: 10px; }

  .rsw-btn {

    &[title="Color"] {
      width: 32px;
      height: 32px;
      background: center / 20px 20px no-repeat url("./fontColorIcon.svg");

      &:hover {
        background: center / 20px 20px no-repeat url("./fontColorIcon.svg"), #eaeaea; } } }

  &__image-loader {
    position: relative;
    height: 260px;
    border: 2px solid #F1F1F1;
    border-radius: 12px;
    margin-bottom: 20px; }

  &__buttons-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px; }

  &__button {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    position: relative;
    min-width: 60px; }

  &__error-message {
    color: red;
    font-size: 10px;
    font-size: 12px;
    position: absolute;
    right: 50%;
    bottom: -20px;
    margin: 0; } }

.swal2-modal .swal2-content {
  input, .swal2-popup .swal2-input {
    height: 46px;
    padding: 10px 14px;
    background-color: $color-light;
    border: 1px solid $color-seashell;
    border-radius: 5px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    letter-spacing: -0.44px;
    font-family: var(--app-font-family);
    font-size: 16px;
    color: $color-ship-gray;

    &:hover {
      border-color: var(--primary-semi-light-color); }
    &:focus {
      border-color: var(--primary-color); }

    @mixin placeholder {
      &::-webkit-input-placeholder {
        @content; }
      &:-moz-placeholder {
        @content; }
      &::-moz-placeholder {
        @content; }
      &:-ms-input-placeholder {
        @content; } }

    @include placeholder {
      letter-spacing: -0.44px;
      font-family: var(--app-font-family);
      font-size: 16px;
      color: $color-silver-chalice; } } }

@import "../../../../../style/index.sass";

.ticket-bar-codes {
  font-family: var(--app-font-family);
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .page-title {
    margin: 0;
    & > a {
      color: inherit;
      text-decoration: none; } }


  .button {
    text-align: right;
    margin-top: auto;
    padding: 30px 0; }

  button {
    width: 150px;
    height: 45px; }

  .Collapsible {
    max-width: 640px;
    border-radius: 10px;
    background-color: $color-light;
    margin-bottom: 10px;
    padding: 15px 20px;

    &__trigger {
      p {
        color: $color-ship-gray;
        letter-spacing: -0.44px;
        line-height: 24px;
        padding-right: 10px; }

      .icon {
        height: 35px;
        width: 35px;
        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 3px;
        background-color: $color-gray; }

      &.is-open {
        .icon {
          transform: rotate(180deg); } } }

    &__contentInner {
      color: $color-ship-gray;
      font-family: var(--app-font-family);
      font-size: 16px;
      letter-spacing: -0.44px;
      line-height: 22px;
      padding: 10px 0 30px 0;

      p {
        margin: 0; }

      .video-wrapper {
        height: 366px;
        width: 100%;
        box-sizing: border-box;
        border: 1px solid $color-silver;
        border-radius: 3px;
        background-color: $color-seashell;
        margin-top: 20px; } } }

  .Stepper {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 40px;
    box-shadow: $table-shadow;
    border-radius: 10px;

    circle {
        fill: var(--secondary-color); }

    .start-tab, .current-tab {
      span {
        border: 1px solid var(--secondary-color);
        color: var(--secondary-color); } }

    .prev-tab {
      span {
        border: 1px solid var(--secondary-color);
        color: transparent;
        position: relative;

        svg {
          display: initial;
          position: absolute;
          left: 50%;
          top: 50%;
          width: 37px;
          height: 37px;
          transform: translate(-50%, -50%); } }

      .Stepper__label {
        color: var(--secondary-color); } }

    & > div {
      width: 25%;

      &:last-child .Stepper__title::after {
        display: none; } }

    &__title {
      background: $color-light;
      padding: 15px 0 15px 20px;
      display: flex;
      position: relative;
      border-radius: 10px;

      &::after {
        content: '';
        position: absolute;
        top: 13px;
        right: 0;
        background: $color-seashell;
        width: 2px;
        height: 45px; } }

    &__indicator {
      position: relative;
      display: block;
      z-index: 2;
      margin-right: 19.5px; }

    &__label {
      position: relative;
      display: block;
      color: $color-ship-gray;
      z-index: 2;
      font-size: 20px;
      margin-top: 5px; }

    &__info {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 37px;
      height: 37px;
      border: 1px solid var(--secondary-color);
      border-radius: 50%;
      background-color: $color-light;
      font-size: 20px;
      font-weight: bold;
      text-align: center;
      color: $color-ship-gray;
      z-index: 2;

      svg {
        display: none; } } }

  .content {
    display: none;

    &.show {
      display: block; } } }

.please-wait-modal {
  padding: 40px 0;

  .swal2-header {
    display: none; }

  p {
    font-size: 20px;
    margin: 0; } }

.important-modal {
  div {
    font-size: 20px;
    display: flex;
    flex-direction: column;

    strong {
      margin-bottom: 20px; }

    .success-content {
      & > p {
        margin: 40px 0 20px 0;
        font-size: 18px;
        text-align: left; }

      .results {
        max-width: 250px;

        p {
          display: flex;
          justify-content: space-between;
          margin: 0 0 5px 0;
          font-size: 16px;
          letter-spacing: -0.44px; } }

      button {
        height: 45px;
        width: 170px;
        margin: 30px auto 0 auto; } } }

  button {
    background: var(--primary-color) !important; } }

.error-modal {

  .swal2-actions {
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin: 30px 0 5px 0; }

  div {
    font-size: 20px;
    display: flex;
    flex-direction: column;

    strong {
      margin-bottom: 20px; } } }

@import "../../../style/index.sass";

.third-tab {
  & > p {
    margin: 0 0 40px 0;
    padding: 0;
    color: $color-ship-gray;
    font-size: 20px; }

  .first-table {
    margin-bottom: 40px;

    .rt-tbody {
      max-height: 188px;
      overflow: hidden; }

    .rt-td:nth-child(3) {
      padding-right: 30px; }

    .dropdown {
      display: none; }

    .rt-tr-group {
      &:nth-child(2) > div {
        opacity: .5; }

      &:nth-child(3) > div {
        opacity: .1; } } }

  .third-table {
    position: relative;

    .ReactTable {
      .rt-tbody {
        overflow: visible;
        min-width: auto !important;
        min-height: 300px;

        .rt-tr-group {
          border: none;

          .rt-td {
            width: 180px !important;
            margin: 0;
            padding-right: 30px;
            border-bottom: solid 1px rgba(0,0,0,0.05); } } }

      .rt-thead {
        min-width: auto !important;
        .rt-tr {
          border: none;
          .rt-th {
            width: 180px !important;
            border-bottom: solid 1px rgba(0,0,0,0.05); } }

        .column {
          &__title {
            color: $color-text-dark;
            font-weight: 700; } } } } }

  .second-table {
    @extend .first-table;
    position: relative;
    .rt-th {

      & > div {
        overflow: visible; } }

    .ignore-msg {
      margin: 0;
      color: $color-ship-gray;
      font-weight: 700;
      & > span {
        padding-left: 5px;
        font-weight: 400; } }

    & .checkbox {
      margin: 0;
      position: absolute;
      z-index: 10;
      top: 22px;
      right: 20px;
      & > label {
        width: 100%;
        padding: 0; } } }

  .second-table.ignore-line {
    .rt-tbody {
      .rt-tr {
        opacity: .5; } } }

  & .loader {
    margin-top: 80px; } }

@import '../../style/index';

.UserInfoSettings {
  font-family: var(--app-font-family);
  box-sizing: border-box;
  margin-bottom: 20px;
  background: #ffffff;
  width: 550px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.18);
  border-radius: 10px;

  &__header {
    border-bottom: 1px solid #e9e9ea;
    padding: 20px;
    letter-spacing: -0.44px; }

  &__heading {
    margin-top: 4px;
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    color: #3f3f41; }

  &__form {
    display: flex;
    align-items: flex-end; }

  &__input-label {
    width: 100%; }

  &__input-name {
    display: block;
    margin-bottom: 12px;
    color: #ABABAB;
    font-size: 16px;
    font-weight: 300; }

  &__input {
    box-sizing: border-box;
    display: block;
    width: 95%;
    height: 44px;
    margin-right: 20px; }

  &__btn {
    box-sizing: border-box;
    display: block;
    padding: 0 44px;
    height: 44px; }

  &__content {
    max-height: 395px;
    overflow: auto;
    transition: height .5s ease-in;

    &--hidden {
      height: 0;
      overflow: hidden;
      opacity: 0;
      transition: height .5s ease-out; } }

  &__item {
    position: relative;
    padding: 20px 56px 20px 20px;
    font-size: 18px;
    font-weight: 500;
    overflow-wrap: break-word;
    transition: all 0.2s linear;
    border-bottom: solid 1px rgba(0,0,0,0.05);
    &:hover {
      background-color: var(--primary-light-color); }

    &:nth-child(2n-1) {
      background-color: #fafafa;
      &:hover {
        background-color: var(--primary-light-color); } } }

  &__clear {
    &:hover {
      cursor: pointer; }
    &::before {
      content: '';
      display: block;
      height: 28px;
      width: 28px;
      position: absolute;
      right: 20px;
      top: 20px;
      background: url("/images/icons/ic_cross.svg") no-repeat center; } } }

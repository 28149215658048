@import "../../style/index.sass";

.field-label {
    display: flex;
    flex-direction: column;
    input:disabled {
        background-color: $color-light;
        color: $color-silver-chalice; }
    &__text {
        font-weight: 600;
        color: $color-ship-gray;
        font-size: 16px;
        @media(max-width: 768px) {
            font-size: 14px; }
        @media (max-width: 380px) {
            font-size: 12px; }
        &-wrapper {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px; }
        &_required {
            font-weight: 400;
            color: $color-light-gray;
            font-size: 13px;
            @media (max-width: 380px) {
                font-size: 11px; } } } }

@import "../../style/index.sass";

.canvas-sidebar {
    overflow-y: auto;
    border: 1px solid $color-seashell;
    padding: 25px 0;
    width: 400px;
    position: relative;
    &__owerlay {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 17;
        background-color: $color-silvery-gray;
        display: flex;
        align-items: center;
        opacity: 0.3;
        justify-content: center; }
    &__btn-minimized-wrapper {
        padding: 0 30px;
        margin-bottom: 25px; }
    &__btn_minimized {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 45px;
        height: 45px;
        svg {
            transform: rotate(-90deg); } }
    &.minimized {
        width: 70px;
        padding: 25px 10px 10px 10px;
        box-sizing: border-box;
        .canvas-sidebar__btn_minimized {
            margin: 0;
            svg {
                transform: rotate(90deg); } } }

    &__control-wrapper {
        padding: 0 30px; }

    .control-field {
        flex-direction: row;
        gap: 10px;
        align-items: center;
        .custom-select {
            flex: 1; }
        &:not(:last-child) {
            margin-bottom: 20px; }
        input {
            flex: 1; }
        &__text {
            color: $color-silvery-gray;
            font-weight: 500;
            font-size: 14px;
            &-wrapper {
                margin: 0;
                width: 90px; } }
        .colorPickerArea {
            flex: 1; }
        .generalArea {
            display: flex;
            .colorSquareInner {
                border: 1px solid $color-silvery-gray; }
            input {
                border-radius: 5px;
                border: 1px solid $color-seashell !important;
                &:hover {
                    border-color: var(--primary-semi-light-color) !important; }
                &:focus {
                    border-color: var(--primary-color) !important; } } } }

    .frame-control {
        padding: 0 30px;
        margin-bottom: 20px;
        &__grid {
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            grid-gap: 10px; }
        &__field {
            width: auto;
            flex-direction: row;
            align-items: center;
            gap: 15px;
            input {
                flex: 1;
                min-width: 80px; }
            &__text {
                color: $color-silvery-gray;
                font-weight: 500;
                font-size: 14px;
                width: 15px;
                text-transform: uppercase;
                &-wrapper {
                    margin: 0; } } } }
    .actions-ul {
        list-style: none;
        padding: 0;
        display: flex;
        justify-content: space-between; }
    .Dropzone {
        border: 1px solid $color-seashell;
        border-radius: 6px; }
    .control-title {
        margin: 0 0 20px 0;
        font-weight: 500;
        font-size: 18px; }
    .canvas-template-select {
        padding: 0 30px;
        margin-bottom: 20px;
        &__text {
            color: $color-silver-chalice;
            font-weight: 400; } }

    .image-control {
        padding: 0 30px; }

    .canvas-objects-control {
        list-style: none;
        padding: 0 0 40px 0;
        margin-bottom: 10px;
        border-bottom: 1px solid $color-seashell;
        &__item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 30px;
            cursor: pointer;
            text-transform: capitalize;
            font-size: 16px;
            button {
                background: transparent;
                border: none;
                cursor: pointer; }
            &_active {
                cursor: default;
                background-color: var(--primary-light-color); }
            &:hover {
                background-color: var(--primary-light-color); }
            &_disabled:hover {
                background: transparent;
                cursor: default; } } }

    .layer-control {
        padding: 0 30px;
        margin-bottom: 20px;
        &__header {
            display: flex;
            justify-content: space-between; }

        &__add-obj-btn {
            cursor: pointer;
            border: none;
            outline: none;
            display: flex;
            align-items: center;
            font-weight: 500;
            font-family: var(--app-font-family);
            color: var(--primary-color);
            background-color: transparent;
            font-size: 18px;

            svg {
              transform: translateY(-1px); }

            path {
              stroke: var(--primary-color);
              stroke-width: 3; } }
        &__buttons {
            display: flex;
            gap: 20px;
            button {
                background-color: transparent;
                border-radius: 0;
                border: none;
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0;


                svg {
                  padding: 6px; } }
            button:first-child, {
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px; }
            button:last-child {
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px; }
            button:not(:last-child) {
                border-right: none; } } }

    .actions-controll {
        &__actions {
            display: flex;
            justify-content: space-between;
            &-button--active {
                border: 1px solid red; }
            > button {
                flex: 1; }
            > button:not(:last-child) {
                margin-right: 5px; } } }
    .text-control {
        margin-top: 20px;
        padding: 0 30px;
        .canvas-overlay__select {
            margin-bottom: 10px; }
        &__fonts-styles {
            flex: 1;
            display: flex;
            gap: 10px;
            .btn-primary {
                padding: 5px;
                flex: 1;
                background: transparent;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid $color-seashell;
                border-radius: 0;
                // border: none
                cursor: pointer;
                &__active {
                    background-color: var(--primary-light-color); }
                &:first-child, {
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px; }
                &:last-child {
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px; }
                &:not(:last-child) {
                    border-right: none; }
                &:hover {
                    background-color: var(--primary-light-color); }

                .btn-primary {
                  border: none;
                  height: 35px;
                  padding: 0;
                  &:hover {
                    box-shadow: none; } } } } } }

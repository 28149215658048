@import "../../style/index";

.promoter {
  padding-left: 60px;
  position: relative;
  overflow: hidden;

  &__nav {
    position: fixed;
    left: 60px;
    top: 0;
    z-index: 5;
    background: $color-light;
    width: calc(100vw - 60px);
    border-bottom: 1px solid $color-seashell; }

  &__wrapper {
    height: calc(100vh - 103px);
    margin-top: 103px;
    padding: 0 20px;
    overflow: auto; }


  &__container {
    max-width: 1310px;
    margin: 0 auto;
    height: 100%;
    position: relative;

    display: flex;
    flex-direction: column; }

  &__title {
    margin: 20px 0; } }

@import "../../style/index.sass";

.edit-user-modal {
    background: $color-seashell;
    padding: 20px 0 20px 20px;

    .swal2-title {
        margin-bottom: 24px; }

    .swal2-content {
        max-height: 570px;
        padding-right: 20px;
        overflow-x: hidden;
        overflow-y: auto; }

    .swal2-label {
        font-size: 16px;
        color: $color-silver-chalice; }

    .modal-input {
        width: 100%;
        box-sizing: border-box;
        margin: 1em auto; }

    .swal2-actions {
        display: flex;

        .swal2-confirm[disabled] {
            background-color: $color-silver-chalice !important;
            box-shadow: none; } }

    .swal2-actions:not(.swal2-loading) {
        .swal2-styled[disabled] {
            opacity: 1; } }

    .swal2-cancel {
        background-color: $color-gray; }

    .email-error {
        color: var(--reject-button-color);
        font-size: 12px;
        display: none; }

    .checkbox-label {
        display: flex;
        user-select: none;

        span {
            margin-top: 2px; } }

    .edit-seats {
        text-align: left;
        font-size: 14px;

        .error-label {
            color: var(--reject-button-color); }

        .title {
            font-size: 16px;
            color: $color-ship-gray; } }

    .input-block {
        text-align: left; } }

.auth {
  a {
    letter-spacing: -0.35px;
    font-family: var(--app-font-family);
    font-size: 14px;
    color: var(--primary-color); }

  &__logo {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    gap: 20px; }

  &__logo-img {
    height: 80px; }

  &__logo-title {
    font-size: 80px;
    font-weight: 700;
    font-family: var(--app-font-family);
    text-transform: uppercase;
    color: var(--primary-color); }

  &__title {
    margin-bottom: 30px;
    font-family: var(--app-font-family);
    font-weight: bold;
    font-size: 20px;
    color: $color-ship-gray;

    @include respond-to('small-max') {
      margin-bottom: 10px; } }

  &__subtitle {
    margin-bottom: 18px;
    letter-spacing: -0.35px;
    font-family: var(--app-font-family);
    font-weight: 600;
    font-size: 14px;
    color: $color-ship-gray; }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;

    input {
      width: 351px;
      margin-bottom: 30px;

      @include respond-to('small-max') {
        width: 100%;
        margin-bottom: 20px; } } }

  .button-next {
    width: 35px;
    height: 35px;
    border: none;
    border-radius: 3px;
    background: url("/images/icons/icon-arrow_white_down.svg") center no-repeat $color-gray;

    &:hover {
      cursor: pointer; }

    @include respond-to('small-max') {
      display: none; }

    @media (max-width: 812px) and (orientation: landscape) {
      display: none; } }

  .button-prev {
    @extend .button-next;
    background: url("/images/icons/icon-arrow_white_top.svg") center no-repeat $color-gray; }

  .btn-primary {
    width: 170px;
    height: 46px;
    padding: 8px 0; }

  .btn-secondary {
    width: 170px;
    height: 46px;
    padding: 8px 0; }

  &__link {
    letter-spacing: -0.35px;
    text-decoration: underline;
    font-family: var(--app-font-family);
    font-weight: 600;
    font-size: 14px;
    color: $color-ship-gray;

    &:hover {
      text-decoration: none;
      color: $color-ship-gray; } }

  &__forgot-password {
    position: relative;

    @include respond-to('small-max') {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center; }

    &__link {
      display: block;
      margin: 0;
      position: absolute;
      right: 0;
      bottom: -2px;
      letter-spacing: 1.05px;
      text-decoration: underline;
      font-family: var(--app-font-family);
      font-size: 12px;
      color: $color-scorpion;

      &:hover {
        text-decoration: none;
        color: $color-ship-gray; }

      @include respond-to('small-max') {
        right: -15px; } } } }

@import "../../style/index.sass";

.scanbits-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: var(--app-font-family);

  width: 480px;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  border-radius: 10px;
  background: #ffffff;

  &__header {
    position: relative;
    padding: 0 30px;
    text-align: center; }

  &__title {
    font-size: 24px;
    font-weight: 600;
    letter-spacing: -0.44px; }

  &__content {
    overflow: auto;
    padding: 0 50px 0;

    .item__title {
      color: #ABABAB; }

    .item__data {} }
  &__footer {
    height: 100px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    .btn-primary,
    .btn-secondary {
      height: 45px;
      width: 170px;
      font-weight: 500;
      margin: 0 10px; } }

  &__item {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.44px;
    color: #000000;
    padding: 12px 30px;
    display: flex;
    flex-direction: column; }

  &__btn-container {
    display: flex;
    justify-content: space-around; }


  &__form {
    display: flex;
    flex-direction: column;
    padding: 0px 30px;
    margin-bottom: 43px;

    input {
      margin: 11px 0 46px; }
    button {
      width: 140px;
      align-self: center; } } }

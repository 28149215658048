.swal2-modal {
  border-radius: 10px;

  .swal2-title {
    font-family: var(--app-font-family);
    font-weight: bold !important;
    font-size: 18px !important;
    color: $color-ship-gray; }

  .swal2-content {
    text-align: center;
    font-family: var(--app-font-family);
    font-size: 18px;
    color: $color-ship-gray; }

  .swal2-actions {
    .swal2-cancel {
      min-width: 170px;
      padding-top: 10px;
      padding-bottom: 10px;

      box-shadow: none;
      background-color: $color-gray !important;

      font-family: var(--app-font-family);
      font-weight: 600;
      font-size: 18px;
      color: $color-light;

      transition: linear .2s;

      &:hover {
        background-image: none !important;

        -webkit-box-shadow: 0px 0px 8px 0px rgba(128, 128, 128, 1);
        -moz-box-shadow: 0px 0px 8px 0px rgba(128, 128, 128, 1);
        box-shadow: 0px 0px 8px 0px rgba(128, 128, 128, 1); } }

    .swal2-confirm {
      @extend .swal2-cancel;

      background-color: var(--primary-color) !important;

      &:hover {
        -webkit-box-shadow: 0px 0px 8px 0px var(--primary-color);
        -moz-box-shadow: 0px 0px 8px 0px var(--primary-color);
        box-shadow: 0px 0px 8px 0px var(--primary-color); } } } }

.partizan-modal {
  .swal2-actions {
    .swal2-confirm {
      @extend .swal2-cancel;

      background-color: $color-partizan-primary !important;

      &:hover {
        -webkit-box-shadow: 0px 0px 8px 0px $color-partizan-primary;
        -moz-box-shadow: 0px 0px 8px 0px $color-partizan-primary;
        box-shadow: 0px 0px 8px 0px $color-partizan-primary; } } } }

@import "../../style/index";

.statistics {
    padding: 40px 16px 80px 16px;
    font-family: var(--app-font-family);
    height: 100vh;
    display: flex;
    flex-direction: column;
    .page-title {
        margin-bottom: 20px; }
    &__actions {
        display: flex;
        justify-content: space-between;
        .download-report {
            display: flex;
            gap: 10px;
            &__button {
                border: none;
                margin-left: 5px;
                background: none;
                letter-spacing: -0.44px;
                text-decoration: underline;
                cursor: pointer;
                font-size: 16px;
                color: var(--primary-color);
                padding: 0; }

            &:hover {
                cursor: pointer; }

            &__wrapper {
                display: flex;
                align-items: center; }
            &:not(:last-child) {
                margin-right: 10px; }

            &:hover {
                cursor: pointer; } }
        &_fields {
            display: flex;
            gap: 10px; }
        .custom-select {
            width: 300px;
            &__control {
                height: 42px; } } }
    .react-tabs {
        flex: 1;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        &__tab {
            border-radius: 5px;
            border: 1px solid $color-seashell;
            padding: 12px 16px;
            font-size: 16px;
            font-weight: 500;
            color: $color-silver-chalice;
            &:first-child {
                border-radius: 5px 0 0 5px; }

            &:last-child {
                border-radius: 0 5px 5px 0; }
            &--selected {
                border-color: var(--primary-color);

                color: var(--primary-color); }
            &-list {
                margin-bottom: 20px;
                border: none; }
            &-panel {
                &--selected {
                    flex: 1; } } } } }

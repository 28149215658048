@import "../../../style/index";

.Collapsible {
  box-shadow: $table-shadow;
  padding: 20px;

  &__trigger {
    min-height: auto;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    p {
      color: $color-scorpion;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0.21px;
      margin: 0; } }

  &__contentInner {
    padding: 10px 0 0 0; } }

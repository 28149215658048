@import "../../style/index";

.canvas-size-menu {
    position: absolute;
    pointer-events: auto;
    top: 10px;
    left: 80px;
    display: flex;
    align-items: center;
    gap: 10px;
    z-index: 15;
    h4 {
        margin: 0;
        font-weight: 500;
        font-size: 20px;
        font-weight: 400; }
    &__button {
        background-color: transparent;
        border: none;
        cursor: pointer; }

    .custom-select {
        width: 150px; } }

.guest-modal {
    .ReactTable {
        .rt-td {
            text-align: left; } }
    .loader {
        height: 400px; }
    .season-pass-info-modal__body {
        h3 {
            margin-bottom: 20px; } }
    .season-pass-info-modal__local-loader {
      position: relative;
      width: 60px;
      height: 100%;

      .loader {
        height: 100%; } } }

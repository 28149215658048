@import "../../../style/index";

.seasonpass-member {
    font-family: var(--app-font-family);
    max-width: 1090px;

    &__header {
        &-logo-wrapper {
            border-top-right-radius: 12px;
            border-top-left-radius: 12px;
            border-left: 1px solid $color-seashell;
            border-right: 1px solid $color-seashell;
            border-top: 1px solid $color-seashell;
            padding: 10px 0;
            background-color: var(--primary-light-color);
            display: flex;
            align-items: center;
            justify-content: center; } }

    &__logo {
        padding: 24px 0;
        @include respond-to('small-max') {
            padding: 12px 0; }
        h2 {
            color: #0C0019;
            text-transform: uppercase;
            font-weight: 500;
            margin: 0 0 0 14px;
            font-size: 28px;
            @include respond-to('small-max') {
                font-size: 21px; } } }

    &__block {
        border: 1px solid $color-seashell;
        // border-left: 1px solid $color-seashell
        // border-right: 1px solid $color-seashell
        // border-bottom: 1px solid $color-seashell
        border-radius: 12px;
        // border-bottom-right-radius: 12px
        // border-bottom-left-radius: 12px
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 56px 48px;
        @include respond-to('small-max') {
            padding: 36px 24px; }
        .title {
            margin-top: 54px;
            font-size: 36px;
            line-height: 0px;
            @include respond-to('small-max') {
                font-size: 26px; } }

        .about {
            font-size: 16px;
            font-weight: 400;
            max-width: 428px;
            text-wrap: wrap;
            text-align: center; }

        .input-email {
            margin-top: 28px;
            width: 400px;
            box-sizing: border-box;
            @include respond-to('small-max') {
                width: 100%; }
            &:hover {
                border-color: $color-partizan-primary; }
            &:focus {
                border-color: $color-partizan-primary; } } }
    @include respond-to('small-max') {
        width: 100%; }

    &__actions {
        display: flex;
        gap: 28px;
        margin-top: 32px;
        @include respond-to('small-max') {
            flex-direction: column; }
        button {
            width: 200px; }

        .white-button {
            background-color: $color-light !important;
            border: 1px solid $color-partizan-primary;
            color: $color-partizan-primary; } }

    .btn-primary {
        border-color: $color-partizan-primary;
        background-color: $color-partizan-primary; }

    &__loader {
        position: relative;
        height: 44px;
        width: 200px; } }

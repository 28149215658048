@import "../../../style/index";

.new-event {
  padding-left: 60px;
  position: relative;
  overflow: hidden;

  &__nav {
    position: fixed;
    left: 60px;
    top: 0;
    z-index: 5;
    background: $color-light;
    width: calc(100vw - 60px);
    border-bottom: 1px solid $color-seashell; }

  &__wrapper {
    height: calc(100vh - 80px);
    margin-top: 80px;
    padding: 0 20px;
    overflow: auto; }


  &__container {
    max-width: 1310px;
    margin: 0 auto;
    height: 100%;
    position: relative;

    display: flex;
    flex-direction: column; }

  &__loader {
    position: fixed;
    left: 60px;
    top: 80px;
    z-index: 20;
    background: $color-light;
    width: 100vw;
    height: 100%;
    margin: 0 -25px; }

  .page-title--not-absolute {
    display: flex;
    justify-content: space-between; }
  .page-title {
    margin-top: 40px;
    background-color: inherit;

    &:first-child {
      margin: 0; }

    a {
      text-decoration: none;
      color: inherit; } }


  &__title {
    margin: 20px 0; }

  button {
    svg {
      display: none; } }

  .btn-primary {
    &:before {
      content: '';
      position: absolute;
      height: 18px;
      width: 18px;
      left: 13px;
      top: 11px;
      background: transparent; }

    &--save {
      padding: 0;
      font-size: 18px;
      font-weight: 600;
      line-height: 22px; }

    &--import {
      &::before {
        background: url('/images/icons/ic_tickets.svg') no-repeat center; } }

    &--barcode {
      margin: 0;
      padding: 0 20px; }

    &--sell {
      width: 180px; }

    &--manage {
      width: 211px; }

    &--reselling {
      padding-right: 32px;
      padding-left: 32px; } }

  .checkbox {
    max-width: max-content; } }


/* Canvas.scss */
@import "../../style/index";

.canvas {
    overflow: hidden;
    width: 100%;
    height: calc(100% - 85px);
    user-select: none;
    background-color: $color-light;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    &__wrapper {
        border: 1px solid var(--primary-color); } }

@import "../../style/index";

.reselling-request-card {
    border-radius: 5px;
    border: 1px solid $color-seashell;
    background: $color-light;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    .checkbox {
        margin: 0; }
    &__hero {
        padding: 16px 24px 20px 24px; }
    &__info {
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 8px;
        column-gap: 12px;
        padding-bottom: 12px;
        h6 {
            margin: 0;
            line-height: 20px;
            font-size: 14px;
            font-weight: 400; }
        p {
            margin: 0;
            font-size: 16px;
            font-weight: 500; } }
    &__checkbox-warpper {
        border-top: 1px solid $color-seashell;
        padding-top: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include respond-to('small-max') {
            flex-direction: column-reverse; } }
    &__header {
        padding: 10px 24px;
        height: 56px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: var(--primary-light-color);
        h6 {
            margin: 0;
            font-size: 18px; } }
    &__status {
        margin-left: 5px;
        padding: 8px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        border-radius: 40px;
        box-sizing: border-box;
        background-color: $color-light;
        &_sold {
            color: #127919; }
        &_in-sale {
            color: #C0731A; }
        &_not-sold {
            color: var(--reject-button-color); } }
    &__coins {
        display: flex;
        align-items: center;
        @include respond-to('small-max') {
            margin-bottom: 15px; }
        &-count {
            margin-left: 8px;
            display: flex;
            padding: 8px 16px;
            align-items: center;
            border-radius: 40px;
            background: var(--primary-light-color);
            span {
                margin-left: 8px;
                font-size: 16px;
                font-weight: 400; } } } }

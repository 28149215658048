.react-datepicker__day--keyboard-selected {
  background-color: var(--primary-color); }

.react-datepicker__day--keyboard-selected:hover {
  background-color: var(--primary-color); }

.react-datepicker__day--selected {
  background-color: var(--primary-color); }

.react-datepicker__day--selected:hover {
  background-color: var(--primary-color); }

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: var(--primary-color); }

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: var(--primary-color); }

@import '../../../style/index';

.choose-tickets-list {
  max-height: 552px;
  min-width: 550px;
  border-radius: 10px;
  background-color: #FFFFFF;
  box-shadow: $table-shadow;
  box-sizing: border-box;
  padding: 20px;
  overflow: auto;
  &__item {
    margin-bottom: 30px;

    &:last-of-type {
      margin-bottom: 0; }

    strong {
      color: $color-ship-gray;
      font-family: var(--app-font-family);
      font-size: 16px;
      font-weight: 600;
      letter-spacing: -0.44px;
      line-height: 22px;
      display: inline-block;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 250px; }
    p {
      height: 22px;
      width: 200px;
      color: $color-ship-gray;
      font-family: var(--app-font-family);
      font-size: 16px;
      letter-spacing: -0.44px;
      line-height: 22px;
      margin: 10px 0 30px; }
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        color: $color-ship-gray;
        font-family: var(--app-font-family);
        font-size: 14px;
        letter-spacing: -0.35px;
        line-height: 19px;

        .Checkbox {
          margin-right: 10px;
          align-items: center; }

        .checkbox input:checked + label:after {
          top: 50%;
          left: 0;
          transform: rotate(45deg) translateY(-85%); } } } }

  & .empty-block {
    font-family: var(--app-font-family);
    color: $color-silver-chalice;
    font-size: 16px;
    letter-spacing: -0.44px;
    line-height: 22px;
    font-weight: 400; } }

.ticket-name {
    color: $color-ship-gray;
    font-family: var(--app-font-family);
    font-size: 16px;
    letter-spacing: -0.44px;
    line-height: 22px;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden; }

@import "../variables";

input, .swal2-popup .swal2-input {
  padding: 10px 14px;
  background-color: $color-light;
  border: 1px solid $color-seashell;
  border-radius: 5px;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  letter-spacing: -0.44px;
  font-family: var(--app-font-family);
  font-size: 16px;
  color: $color-ship-gray;

  &:hover {
    border-color: var(--primary-semi-light-color); }
  &:focus {
    border-color: var(--primary-color); }

  @mixin placeholder {
    &::-webkit-input-placeholder {
      @content; }
    &:-moz-placeholder {
      @content; }
    &::-moz-placeholder {
      @content; }
    &:-ms-input-placeholder {
      @content; } }

  @include placeholder {
    letter-spacing: -0.44px;
    font-family: var(--app-font-family);
    font-size: 16px;
    color: $color-silver-chalice; }

  &.search-icon {
    padding-left: 36px;
    background-color: white;
    background-image: url("../images/icon-search.svg");
    background-position: 10px 10px;
    background-repeat: no-repeat; } }


@import "../../../style/index.sass";

.ticket-rule {
  font-family: var(--app-font-family);
  margin-top: 20px;
  width: 50%;
  @media all and (min-width: 1220px) {
    min-width: 500px; }
  @media all and (max-width: 1220px) {
    width: 47%;
    &:nth-child(2n+1) {
      margin-right: 6%; } }
  @media all and (max-width: 768px) {
    width: 100%;
    margin-right: 0 !important; }
  &__title {
    margin-top: 40px;
    color: $color-ship-gray;
    font-family: var(--app-font-family);
    font-size: 18px;
    line-height: 24px;
    font-weight: 400; }
  &__wrap {
    max-width: 500px;
    border-radius: 10px;
    background-color: $color-light;
    padding: 20px;
    position: relative;
    box-shadow: $table-shadow;
    .close {
      position: absolute;
      right: -11px;
      top: -10px;
      width: 20px;
      height: 20px;
      background: $color-iron;
      border-radius: 50%;
      cursor: pointer;
      border: none; }

    .close:before, .close:after {
      position: absolute;
      left: 9px;
      top: 2px;
      content: ' ';
      height: 15px;
      width: 2px;
      background-color: #808080; }

    .close:before {
      transform: rotate(45deg); }

    .close:after {
      transform: rotate(-45deg); }
    .ticket-rule {
      &__row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        &:nth-child(4) {
          & > div {
            width: 100%; } } }
      &__block {
        display: flex;
        flex-direction: column;

        label {
          color: $color-silver-chalice;
          font-family: var(--app-font-family);
          font-size: 16px;
          letter-spacing: -0.44px;
          line-height: 22px;
          margin-bottom: 10px;
          margin-top: 30px; }

        input {
          box-sizing: border-box;
          width: 221px;
          border: 1px solid $color-seashell;
          border-radius: 2px; }

        textarea {
          box-sizing: border-box;
          height: 139px;
          width: 100%;
          border: 1px solid $color-seashell;
          border-radius: 2px;
          padding: 12px 16px;
          color: $color-ship-gray;
          font-size: 16px;
          font-family: var(--app-font-family);
          line-height: 22px;
          letter-spacing: -0.44px;
          resize: none;
          outline: none;
          -webkit-appearance: none;
          -moz-appearance: none; }

        textarea {
          @include placeholder {
            color: $color-silver-chalice; } } } } }

  @media all and (max-width: 768px) {
    .ticket-rule {
      &__wrap {
        max-width: 100%; }
      &__row {
        flex-direction: column; }
      &__block input {
        width: 100% !important; } }
    .react-datepicker__input-container {
      display: block; } } }

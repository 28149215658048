@import "../../../style/index";

.StatusLabel {
    color: $color-ship-gray;
    border-radius: 40px;
    width: 104px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;

    &__Text {
        vertical-align: middle; }

    &__Pending-To-Approved {
        background-color: $color-creamy-vanilla;
        width: 166px; }

    &__Created {
        background-color: $color-light-blue; }

    &__Approved {
        background-color: $color-minty-green; }

    &__Error {
        background-color: $color-rosewater-pink; }

    &__Restricted {
        background-color: $color-rosewater-pink; }

    &__Cancelled {
        background-color: $color-creamy-vanilla; }

    &__Refunded {
        background-color: $color-lavender-bliss; }

    &__NFCBought {
        background-color: $color-mint;
        color: $color-light; }

    &__NFCBuy {
        color: $color-light; }

    &__Processing {
        background-color: $color-light-gray; } }

.StatusLabel.IgnoreWidth {
    padding: 0.05em 0.4em; }

.StatusLabel.Mint {
    background-color: $color-mint;
    color: $color-light; }

.StatusLabel.MintDark {
    background-color: $color-mint-dark;
    color: $color-light; }

.StatusLabel.GoldDark {
    background-color: $color-partizan-gold-dark;
    color: $color-light; }

.StatusLabel.PurpleDark {
    background-color: $color-lavender-bliss-dark;
    color: $color-light; }

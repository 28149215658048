@import "../../../style/index.sass";

.first-tab {
  & > p {
    margin: 0;
    padding: 0;
    color: $color-ship-gray;
    font-size: 20px; }

  .download {
    margin: 0 0 10px 0;

    & > button {
      height: auto; }

    button {
      color: var(--primary-color);
      font-size: 18px;
      line-height: 24px;
      text-decoration: underline;
      width: 170px;
      background: none;
      text-align: left;
      border: none;
      cursor: pointer;
      padding: 0; } }

  .choose-file {
    position: relative;
    max-width: 641px;

    & > p {
      color: $color-ship-gray;
      font-size: 20px;
      font-weight: bold;
      line-height: 27px;
      margin-top: 0; } } }

@import "../../../style/index";

.page-title {
  color: $color-ship-gray;
  font-family: var(--app-font-family);
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  margin-bottom: 36px;

  a {
    transition: .2s linear;
    font-weight: 300;
    color: $color-ship-gray;

    &:hover {
      color: var(--primary-color) !important; } }

  &--not-absolute {
    position: static!important;
    transform: translateX(0)!important; }

  @include respond-to('small-max') {
    position: absolute;
    top: 10px;
    left: 50%;
    &:first-of-type {
      display: none;
      transform: translateX(-50%);
      background: white; } }

  @media only screen and (device-width: 375px) and (device-width: 812px) and (orientation: landscape) {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%); } }

@import "../variables";

* {
  outline: none !important; }

body {
  margin: 0;

  background-color: $color-light; }

.form-error {
  margin-top: 0;

  font-family: var(--app-font-family);
  font-size: 14px;
  color: var(--reject-button-color); }

.page-footer {
  padding: 30px 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end; }

@import "../../../style/index";

.column {
  position: relative;
  max-width: 150px;
  width: 100% !important;

  & > p {
    margin: 0; }

  .toggle-submenu {
    content: '';
    position: absolute;
    right: 25px;
    top: 8px;
    border: 6px solid transparent;
    border-top: 8px solid $color-dark;
    cursor: pointer;

    &.show {
      transform: rotate(180deg);
      top: 2px; } }

  .close {
    position: absolute;
    right: 0;
    top: 8px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    border: none;
    background: transparent;

    &::before, &::after {
      position: absolute;
      left: 9px;
      top: -2px;
      content: ' ';
      height: 13px;
      width: 2px;
      background-color: $color-dark; }

    &::before {
      transform: rotate(45deg); }

    &::after {
      transform: rotate(-45deg); } } }

.dropdown {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.18);
  width: 149px;
  border-radius: 5px;
  background-color: $color-light;
  padding: 0 10px;
  position: absolute;
  top: 50px;
  left: 0;

  p {
    margin: 0;
    padding: 10px 0;
    box-sizing: border-box;
    border-bottom: 1px solid $color-seashell;
    color: $color-ship-gray;
    font-size: 14px;
    letter-spacing: -0.35px;
    cursor: pointer;

    &:last-child {
      border-bottom: none; } } }

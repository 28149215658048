// fonts
@import "~npm-font-open-sans/open-sans.css";

@import "globals";

// common
@import "common/common";

// elements
@import "common/input";
@import "common/checkbox";
@import "common/button";
@import "common/table";
@import "common/toasts";
@import "common/link";
@import "common/react-datepicker";
@import "common/select";
@import "common/radiobutton";

// Modals
@import "modals/common";
@import "modals/blocked-user-modal";
@import "modals/approve-user-modal";
@import "modals/delete-user-modal";
@import "modals/edit-user-modal";
@import "modals/public-user-modal";
@import "modals/refund-tickets-modal";
@import "modals/confirm-refund-tickets-modal";
@import "modals/confirm-cancel-offer-modal";
@import "modals/warning-modal";

.react-datepicker {
  button {
    padding: 0!important; } }

@import "../index.sass";

.approve-user-modal {
    padding: 32px;

    .swal2-title {
        display: none; }

    .title {
        font-family: var(--app-font-family);
        font-weight: bold !important;
        font-size: 18px !important;
        color: $color-ship-gray;
        margin: 0 0 37px 0; }

    .swal2-label {
        @extend label;
        position: relative;
        cursor: pointer;
        margin-top: 1px;
        letter-spacing: -0.44px;
        font-family: var(--app-font-family);
        font-size: 16px;
        color: $color-ship-gray;
        display: flex;
        align-items: center;
        &:before {
            content: '';
            min-width: 22px;
            height: 22px;
            margin-right: 12px;
            margin-top: 2px;

            position: relative;
            cursor: inherit;
            vertical-align: middle;
            -webkit-appearance: none;

            display: inline-block;
            background-color: transparent;
            border: 1.5px solid $color-scorpion; } }

    .swal2-checkbox {
        margin-top: 40px;
        margin-left: 7px;
        font-size: 14px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        input {
            width: initial;
            height: initial;
            padding: 0;
            margin-bottom: 0;
            display: none;
            cursor: pointer;

            &[disabled] ~ label {
                opacity: .5;
                cursor: default;
                tab-index: -1; } } }

    .swal2-checkbox input:checked + .swal2-label:after {
        content: '';
        width: 6px;
        height: 14px;
        position: absolute;
        top: 5px;
        left: 9px;
        display: block;
        border: solid $color-scorpion;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg); } }

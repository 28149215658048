@import "../../../style/index";

.scant-teams-mobile-list {
  &__item {
    border-bottom: 1px solid $color-seashell;
    padding: 0 0 5px;
    max-height: 250px;
    overflow: hidden;

    &.active {
      max-height: none;
      button {
        transform: rotate(180deg); } }

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h3 {
        color: $color-ship-gray;
        font-family: var(--app-font-family);
        font-size: 18px;
        font-weight: bold;
        line-height: 28px;
        margin: 10px 0; }
      button {
        width: 25px;
        height: 25px;
        padding: 0;
        background: $color-gray;
        border-radius: 3px;
        box-shadow: none!important;

        transition: none!important;
        svg {
          width: 25px;
          height: 25px; } } }

    &__statements {
      margin: 10px 0 !important;

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      line-height: 19px;
      letter-spacing: -0.35px;
      font-family: var(--app-font-family);
      font-size: 14px !important;
      color: $color-silver-chalice; } }

  &__block {
    &__title {
      color: $color-ship-gray;
      font-family: var(--app-font-family);
      font-size: 14px;
      font-weight: 600;
      letter-spacing: -0.35px;
      line-height: 19px; }
    &__dates {
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        height: 34px;
        width: 51px;
        color: $color-ship-gray;
        font-family: var(--app-font-family);
        font-size: 12px;
        letter-spacing: 1.05px;
        line-height: 17px;
        text-align: center; } }

    ul {
      margin: 10px 0 0;
      padding: 0;
      list-style: none;
      li {
        color: $color-ship-gray;
        font-family: var(--app-font-family);
        font-size: 14px;
        letter-spacing: -0.35px;
        line-height: 19px;
        margin-top: 8px; } } }
  @media all and (min-width: 769px) {
    display: none; } }

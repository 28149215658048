@import "../../../style/index";

.promoters-empty-state {
  max-width: 377px;
  margin: 0 auto;
  text-align: center;
  &__title {
    color: $color-ship-gray;
    font-family: var(--app-font-family);
    font-size: 20px;
    font-weight: bold;
    line-height: 27px;
    text-align: center;
    margin: 60px 0 45px; }
  img {
    max-width: 100%;
    margin-bottom: 30px; }
  p {
    color: $color-dark;
    font-family: var(--app-font-family);
    font-size: 18px;
    letter-spacing: 0.65px;
    line-height: 30px;
    margin: 5px 0; }

  @media all and (max-width: 768px) {
    &__title {
      margin: 20px 0;
      font-size: 18px;
      img {
        margin-bottom: 10px; } }
    p {
      font-size: 14px;
      line-height: 20px; } } }


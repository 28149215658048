@import "../../../../../style/index";

.wallets {
  color: $color-text-dark;
  font-family: var(--app-font-family);
  position: relative;
  flex-grow: 1;

  display: grid;
  grid-template-columns: 1fr 320px;
  column-gap: 20px;

  .add-event__row {
    &:not(:last-child) {
      margin-bottom: 20px; }
    & > div {
      &:nth-child(1),
      &:nth-child(2) {
        margin-right: 60px; } } }

  .add-event__block {
    width: 300px; }

  .wallets__select-block, .wallets__select {
    width: 200px;
    margin-bottom: 40px; }

  &__image-container {
    position: relative;

    .IconButton {
      height: 35px;
      width: 35px;
      padding: 7px;
      position: absolute;
      top: 60px;
      right: 10px;

      svg {
        display: initial; }

      .IconButton {
        top: 0;
        left: 0; } } }

  .Dropzone {
    border-radius: 12px;
    border: 2px solid $color-seashell;
    padding: 0;
    margin-bottom: 20px;
    overflow: hidden;
    height: 240px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    align-items: center;
    justify-content: center;

    &__img {
      height: 100%; } }

  &__images-part {
    display: grid;
    column-gap: 10px;

    &--apple {
      grid-template-columns: 1fr 1fr 1fr; }

    &--google {
      grid-template-columns: 1fr 3fr; } }

  &__image-label {
    display: block;
    color: $color-silver-chalice;
    font-size: 16px;
    letter-spacing: -0.44px; }

  &__image-sublabel {
    color: $color-silver-chalice;
    font-size: 12px;
    margin: 0 0 10px; }

  &__preview-wrapper {
    position: relative; }

  &__preview {
    box-sizing: border-box;
    width: 300px;
    height: 570px;
    background-color: darkgray;
    position: fixed;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    border: 5px solid black;
    border-radius: 30px;
    padding: 40px 10px 20px;
    box-shadow: 0px 4px 8px 0px black; }

  &__info-message {
    color: $color-silver-chalice;
    font-size: 12px;
    margin-top: 40px; } }

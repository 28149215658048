@import "../../../style/index.sass";

.second-tab {
  & > p {
    margin: 0 0 40px 0;
    padding: 0;
    color: $color-ship-gray;
    font-size: 20px; }

  .input-block {
    width: 451px;

    input {
      box-sizing: border-box;
      max-width: 451px;
      width: 100%;
      border: 1px solid $color-gray;
      border-radius: 2px;
      padding: 16px 15px;
      color: $color-ship-gray; } }

  .select-provider {
    &__control {
      height: 56px;
      padding-left: 6px;

      background-color: $color-light;
      border: 1px solid $color-seashell;
      border-radius: 2px;
      box-shadow: none;

      &:hover {
        border: 1px solid $color-seashell; } }

    &__placeholder {
      font-family: var(--app-font-family);
      font-size: 16px; }

    &__indicators {
      display: none; } } }

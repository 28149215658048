@import "../../../style/index";

.selling-events-card {
    padding: 20px 24px;
    display: flex;
    gap: 12px;
    border-radius: 5px;
    border: 1px solid $color-seashell !important;
    cursor: pointer;
    img {
        box-sizing: border-box;
        border-radius: 5px;
        width: 145px;
        height: 145px; }
    &__hero {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        h4 {
            font-size: 18px;
            font-weight: 700;
            margin: 0; }
        h6 {
            font-size: 14px;
            font-weight: 400;
            margin: 0; } }

    &__info {
        display: flex;
        align-items: center;
        gap: 5px;
        &:first-of-type {
            margin-left: -2px;
            margin-bottom: 5px; }
        path {
            fill: $color-text-dark; } } }

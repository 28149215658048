@import "../../../style/index";

.season-pass-info-modal {
    &__header {
        padding: 10px 0;
        background-color: #F2F4FE; }
    &__body {
        padding: 24px 20px 0px 20px;
        display: flex;
        flex-direction: column;

        h3 {
            font-size: 36px;
            font-weight: 700;
            margin: 0;
            @media(max-width: 768px) {
                font-size: 26px; } }

        img {
            max-height: 180px;
            margin: 24px 0; }
        p {
            margin: 0;
            font-size: 16px;
            font-weight: 400;
            @media(max-width: 768px) {
                font-size: 14px; } } }
    &__additionalText.season-pass-info-modal__additionalText {
        margin-top: 10px;
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        @media(max-width: 768px) {
            font-size: 13px; } } }

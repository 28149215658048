// fonts
$fs-sinclair-trial: "FS Sinclair Trial";

$app-font-family: "FS Sinclair Trial", "Open Sans", "Helvetica Neue", sans-serif;

// colors
// white / black
$color-light: #FFFFFF;
$color-seashell: #F1F1F1;
$color-iron: #e6e6e6;
$color-silver: #C2C2C2;
$color-silver-chalice: #ABABAB;
$color-silvery-gray: #9FA0A6;
$color-light-gray: #B3B3B3;
$color-gray: #818181;
$color-scorpion: #585858;
$color-ship-gray: #3F3F41;
$color-text-dark: #212529;
$color-dark: #000000;

$color-hollywood-cerise: #EB008B; // 0
$color-crimson: #ED1343; // 14
$color-pink: #E60073; // 0
$color-malachite: #15C151; // 4
$color-han-blue: #6071B5;
$color-light-blue: #F2F4FE;
$color-mint: #95BAAB; // 4
$color-mint-dark: #668B8B; // status
$color-minty-green: #D9F9DB; // status
$color-rosewater-pink: #FBDCDC; // status
$color-creamy-vanilla: #FDF2D8; // status
$color-serene-blue: #C0C7E2; // 5
$color-lavender-bliss: #B663E9;
$color-lavender-bliss-dark: #8e63a8; // status
$color-partizan-primary: #d7b36c;
$color-partizan-gold-dark: #ae9c74;
// box shadows
$table-shadow: 0 0 8px 0 rgba(0,0,0,0.18);
$toolip-shadow: 0px 1px 10px 0px #5F5C601F;

.history-modal {
  .ReactTable {
    height: 60vh;
    overflow: hidden;

    .rt-td, rt-th {
      text-align: left; } }

  &__title {
    font-size: 18px;
    text-align: center;
    font-weight: 700;
    margin-bottom: 20px; }

  &__info-list {
    margin: 0 auto 30px;
    text-align: left;
    font-size: 16px; }

  &__info-item {
    margin-bottom: 8px; } }

.checkbox {
  display: block;
  margin-bottom: 15px;


  input {
    width: initial;
    height: initial;
    padding: 0;
    margin-bottom: 0;

    display: none;
    cursor: pointer;

    &[disabled] ~ label {
      opacity: .5;
      cursor: default;
      tab-index: -1; } }

  label {
    position: relative;
    cursor: pointer;

    letter-spacing: -0.44px;
    font-family: var(--app-font-family);
    font-size: 16px;
    color: $color-ship-gray;
    display: flex;
    align-items: center;
    &:before {
      content: '';
      min-width: 22px;
      height: 22px;
      margin-right: 12px;
      margin-top: 2px;

      position: relative;
      cursor: inherit;
      vertical-align: middle;
      -webkit-appearance: none;

      display: inline-block;
      background-color: transparent;
      border: 1.5px solid $color-scorpion; } } }
.checkbox input:checked + label:after {
  content: '';
  width: 6px;
  height: 14px;

  position: absolute;
  top: 50%;
  left: 0;
  transform: rotate(45deg) translateY(-85%);

  display: block;
  border: solid $color-scorpion;
  border-width: 0 2px 2px 0; }

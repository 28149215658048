@import "../../../../../style/index";

.pdf {
  margin-right: -35px;
  display: flex;
  height: calc(100vh - 147px);
  color: $color-text-dark;
  font-family: var(--app-font-family);
  &__canvas-wrapper {
      flex: 6; }

  .canvas {
    height: calc(100% - 85px);
    background-color: $color-light-gray; }

  .canvas-size-menu {
    top: 10px;
    right: 0;
    left: auto;

    .canvas-objects-control {
      margin: 0; } }

  .minimized {
    width: 80px; }


  &__save {
      border-top: 1px solid $color-seashell;
      padding: 20px 30px 20px 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 15px;
      .btn-primary, .btn-red-outline {
          width: 140px; }

      .checkbox {
        margin-bottom: 0;
        margin-right: auto; } }

  button {
    svg {
      display: initial; } }

  .layer-control {
    &__header {
      margin-bottom: 20px;

      .control-title {
        margin: 0; } }

    &__buttons {
      svg {
        width: 100%; } } } }

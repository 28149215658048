@import "../../style/index";

@mixin getSidesPadding() {
    padding-left: 48px;
    padding-right: 48px;
    @include respond-to('small-max') {
        padding-left: 20px;
        padding-right: 20px; } }

.selling-events {
    font-family: var(--app-font-family);
    border: 1px solid $color-seashell;
    border-radius: 12px;
    padding: 0;
    color: $color-text-dark;
    @media (min-width: 768px) and (max-width: 1450px) {
        margin-left: auto; }
    &__wrapper {
        padding: 60px 16px;
        background-color: $color-light;
        @include respond-to('small-max') {
            padding: 0 16px 30px 16px; } }
    &__logo {
        padding: 25px 0;
        display: flex;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        justify-content: center;
        align-items: center;
        background-color: var(--primary-light-color);
        img {
            height: 46px;
            @include respond-to('small-max') {
                height: 36px; } } }
    &__header {
        display: flex;
        gap: 20px;
        margin-bottom: 32px;
        margin-top: 32px;
        @include getSidesPadding();
        @include respond-to('small-max') {
            flex-direction: column; }
        > * {
            flex: 1; }
        h4 {
            margin: 0;
            font-size: 36px;
            font-weight: 700; } }
    &__hero {
        margin-bottom: 40px;
        height: 540px;
        overflow: auto;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 187px;
        gap: 25px;
        @include getSidesPadding();
        @include respond-to('small-max') {
            grid-template-columns: 1fr; } } }

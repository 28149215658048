@import "../../../../../style/index.sass";

.seats {
    font-family: var(--app-font-family);
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    input {
        max-width: 300px;
        margin-bottom: 20px; }
    .custom-select {
        max-width: 300px;
        margin-bottom: 20px; }
    & > .seats-io-link {
        color: $color-text-dark;
        font-size: 18px;
        line-height: 24px;
        text-decoration: underline;
        padding: 0;
        margin: 0;
        width: max-content;

        & ~ .seats-io-link {
            margin: 0 0 23px 0; } }

    &__download {
      cursor: pointer;

      &:hover {
        cursor: pointer;
        svg {
            g {
              fill: var(--secondary-color); } } }

      & > button {
          height: auto; }

      button {
          color: $color-text-dark;
          font-size: 18px;
          line-height: 24px;
          text-decoration: underline;
          background: none;
          text-align: left;
          border: none;
          cursor: pointer;
          padding: 0;

          &:hover {
            color: var(--secondary-color); } } }


    &__submit {
        align-self: flex-end;
        width: 150px; }

    &__de-restrict {
        align-self: flex-end;
        width: 186px; }

    &__choose-file {
        position: relative;
        max-width: 641px;

        & > p {
            color: $color-ship-gray;
            font-size: 20px;
            font-weight: bold;
            line-height: 27px;
            margin-top: 0; } }

    .btn-secondary {
        padding: 0 24px; }

    &__upload {
        flex: 1; } }

.generate-pdfs {
    min-height: 100vh;
    height: auto;

    &__action {
        display: flex;
        > *:not(:last-child) {
            margin-right: 20px; } }

    .ReactTable {
        margin-bottom: 30px; } }

.event-action-selector {
    margin-top: 20px;
    margin-bottom: 80px; }

.download-actions-container {
    display: flex;
    flex-direction: row;
    column-gap: 40px;
    margin-bottom: 20px; }

.download-action {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 5px; }

.seats-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: flex-end;

    &--right {
      display: flex;
      align-items: flex-end;
      margin: 0;

      button {
        margin-left: 20px; }

      .btn-primary {
        width: 200px; } }

    .checkbox {
      margin: 0; } }

.seats-io-link {
  &:hover {
    color: var(--secondary-color); } }

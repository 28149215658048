@import "../../style/index";

.stats-scan {
    font-family: var(--app-font-family);

    padding-top: 40px;
    padding-bottom: 40px;
    .download-report {
        display: flex;
        gap: 10px;
        &__button {
            border: none;
            margin-left: 5px;
            background: none;
            letter-spacing: -0.44px;
            text-decoration: underline;
            cursor: pointer;
            font-size: 16px;
            color: var(--primary-color);
            padding: 0; } }
    &__fields-group {
        display: flex;
        gap: 10px;
        .custom-select {
            flex: 1;
            height: auto;
            min-height: 42px; }
        .custom-select__control {
            height: auto;
            min-height: 42px; } }
    &__graphic-warpper {
        border-radius: 5px;
        border: 1px solid $color-seashell;
        background: $color-light;
        margin: 20px 0;
        padding: 20px;
        background-color: $color-light; } }

@import '../../style/index.sass';
$primary-color: $color-gray;
@import 'loaders.css/src/animations/ball-scale-ripple-multiple.scss';


.loader-hidden {
  display: none; }

.loader-active {
  display: flex;
  align-items: center;
  justify-content: center;

  &.fs {
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    background: white; } }

.loader-default {
  backdrop-filter: blur(2px);
  width: 100%;
  height: 100%;
  margin: 0;
  box-shadow: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10; }

@import "../../style/index";

.membership-managment {
    .page-title {
        margin: 36px 0; }
    .seasonpass__select__menu {
        z-index: 4; }

    .searchPanel {
        display: flex;
        margin-bottom: 44px;
        align-items: center;
        gap: 1rem;
        height: 46px;

        button {
          margin-left: auto;
          padding: 10px 30px;
          flex-shrink: 0; }

        .checkbox {
            margin: 0; }

        &__select {
            width: 256px;
            font-family: var(--app-font-family); }

        &__search {
            width: 418px; } }

    &__buttons {
      display: flex;
      gap: 1rem;
      margin-left: auto; }

    &__reset-button {
      background-color: var(--secondary-color) !important;

      &:hover {
        cursor: pointer;

        -webkit-box-shadow: 0px 0px 8px 0px var(--secondary-color) !important;
        -moz-box-shadow: 0px 0px 8px 0px var(--secondary-color) !important;
        box-shadow: 0px 0px 8px 0px var(--secondary-color) !important; } }


    .payment-status {
        display: flex;
        gap: 3px;
        cursor: pointer; }

    .buttons-block {
        display: flex;
        gap: 14px;
        padding: 0px 8px;

        &.options {
            padding: 7px 24px; }

        .IconButton {
            height: 35px;
            width: 35px;
            border-radius: 5px;
            padding: 0;
            font-size: 12px;

            .IconButton {
              height: 33px;
              width: 33px;
              &.primary, &.red, &.green, &.gray, &.lavender {
                border: none; } }

            &.red {
                border: 1px solid var(--reject-button-color);
                background-color: $color-light; }
            &.green {
                border: 1px solid $color-malachite;
                background-color: $color-light; }
            &.primary {
                border: 1px solid var(--primary-color);
                background-color: $color-light; }
            &.gray {
                border: 1px solid $color-silver-chalice;
                background-color: $color-light; }
            &.lavender {
                border: 1px solid $color-lavender-bliss;
                background-color: $color-light; }

            svg {
                vertical-align: middle; } } }

    .ReactTable {
        height: calc(100vh - 200px);
        overflow: hidden;

        .rt-table {
            padding: 0; }

        .rt-thead {
            flex-grow: 0;
            padding: 0;
            &.-header {
                .rt-tr {
                    .rt-th {
                        display: flex;
                        align-items: center;
                        padding: 20px;
                        transition: linear .07s;

                        padding: 20px; } } }

            .rt-resizable-header:last-child {
                overflow: hidden; } }

        .rt-tbody {
            padding: 0;
            box-sizing: content-box;
            flex: 99999 1 auto;

            .rt-tr-group {
                display: block;
                border: none;

                &:hover {
                  background-color: var(--secondary-light-color); }

                .rt-td:last-child {
                    position: sticky;
                    z-index: 1; }

                .rt-td {
                    overflow: hidden;
                    padding: 20px;
                    border-bottom: solid 1px rgba(0, 0, 0, 0.05);

                    &:not(:last-child) {
                      background: transparent;
                      cursor: pointer; }

                    ul {
                        display: flex;
                        flex-direction: column;
                        margin: 0;
                        padding: 0;
                        list-style: none;
                        gap: 19px; }

                    .accordion-btn {
                        transition: none;
                        &.disable {
                            cursor: default;
                            &:hover {}
                            box-shadow: none; } } } }

            .rt-tr {
                display: flex;
                max-height: 68px;

                &.active {
                    max-height: none;
                    align-items: flex-start;
                    .accordion-btn {
                        svg {
                            transform: rotate(180deg); } } } } } }

    .react-contextmenu {
        border-radius: 5px;
        background: $color-light;
        box-shadow: 0px 1px 10px 0px #5F5C601F; }

    .react-contextmenu-item {
        padding: 6px 12px;
        font-size: 18px;
        color: $color-ship-gray;
        &:hover {
            background: var(--primary-light-color); } } }


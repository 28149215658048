@import "../../../../../style/index";

.tickets {
  padding-bottom: 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__buttons {
    display: flex;
    justify-content: flex-end; }

  &__add-button {
    padding: 0 20px; }

  .ReactTable {
    margin-bottom: 20px;

    &.scroll {
      .rt-tbody {
        height: 480px;
        overflow-y: auto; } }

    .rt-thead.-header .rt-tr {
      .rt-th {
        padding: 20px 5px;

        overflow: visible;

        &:nth-child(2) {
          width: 400px !important; } } }

    .rt-tbody {
      padding: 0;
      overflow: hidden;

      & .rt-td:nth-child(n + 2) {
        margin-right: 0 !important;

        &:nth-child(2) {
          width: 400px !important; } }

      & .rt-td:last-child() {
        display: flex;
        justify-content: flex-end;
        padding-right: 48px; }

      .rt-tr-group {
        cursor: pointer;
        padding: 0 20px;
        transition: all 0.2s linear;

        .rt-td {
          padding: 20px 5px;
          overflow: hidden;
          text-overflow: ellipsis; }

        &:hover {
          background-color: var(--secondary-light-color); } } } }

  .empty-block {
    display: flex;
    flex-direction: column;
    padding: 70px 0;
    img {
      margin: 0 auto;
      width: 400px;
      height: 169px;
      @media (max-width: 767px) {
        width: 280px;
        height: auto; } }
    &__text {
      text-align: center;
      color: $color-ship-gray;
      font-family: var(--app-font-family);
      font-weight: 400;
      font-size: 20px;
      line-height: 23px;
      margin-bottom: 0px;
      font-weight: bold;
      position: relative; } }

  &__list {
    display: none; }

  @media all and (max-width: 767px) {
    .ReactTable {
      display: none; }
    &__list {
      display: block;
      &__item {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        border-top: 1px solid $color-seashell;
        &__title {
          color: $color-ship-gray;
          font-family: var(--app-font-family);
          font-size: 16px;
          font-weight: 600;
          letter-spacing: -0.44px;
          line-height: 22px;
          overflow: hidden;
          text-overflow: ellipsis;
          &:hover {
            cursor: pointer; } }
        &__counts {
          color: $color-silver-chalice;
          font-family: var(--app-font-family);
          font-size: 16px;
          letter-spacing: -0.44px;
          line-height: 22px;
          text-align: right;
          /*margin-right: 60px*/ }
        &__import {
          font-family: var(--app-font-family);
          position: absolute;
          z-index: 1;
          font-size: 12px;
          right: 20px;
          color: #ABABAB;
          text-decoration: underline;
          &:hover {
            cursor: pointer; } } } } } }


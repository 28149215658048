@import "../../style/index.sass";

.promoters {
  padding-top: 40px;
  & .loader {
     height: 50vh; }
  .page-title:first-of-type {
    display: block;
    @media (max-width: 768px) {
      width: 100%;
      padding: 10px;
      margin: 0;

      position: fixed;
      top: 0;
      z-index: 2;
      box-sizing: border-box;

      text-align: center;
      font-size: 18px;
      font-weight: bold;
      background-color: $color-light; } }
  input {
    @media (max-width: 768px) {
      margin-top: 25px;
      margin-bottom: 20px; } }
  .ReactTable {
    height: calc(100vh - 260px);

    @media (max-width: 768px) {
      display: none; }
    .rt-thead.-header {
      box-shadow: none;
      .rt-th {
        &:nth-child(2) {
          width: 170px !important; }
        &:nth-child(4), &:nth-child(5) {
          width: 50px !important;
          & > div {
            overflow: hidden;
            text-overflow: ellipsis; } } }
      @media (max-width: 1300px) {
        min-width: 100%;
        padding: 0 0 0 20px; } }
    .rt-tbody {
      padding: 0;
      .rt-tr-group {
        flex: none;

        padding: 0 20px;


        &:hover {
          background-color: var(--primary-light-color); } }

      @media (max-width: 1300px) {
        min-width: 100%;
        padding: 0; } }
    button {
      height: 45px;
      width: 150px;
      position: relative;
      top: -10px;
      left: 10px; }
    .rt-tbody .rt-td {
      position: relative;
      top: 10px;
      padding-right: 25px;
      border: none;
      &:nth-child(4), &:nth-child(5) {
        padding-left: 10px;
        padding-right: 0;
        width: 50px !important; }
      &:nth-child(2) {
        width: 170px !important; }
      @media (max-width: 1300px) {
        padding-right: 10px;
        button {
            width: 80px; } } } }
  .promoters-mobile-table {
    display: none;
    @media (max-width: 768px) {
      display: block; }
    &__row {
      border-top: 1px solid $color-seashell;
      padding: 10px 0;
      font-family: var(--app-font-family); }
    &__row-top {
      display: flex;
      justify-content: space-between;
      .promoter-name {
        font-weight: 600;
        letter-spacing: -0.44px; }
      .promoter-email {
        font-size: 14px;
        letter-spacing: -0.35px;
        margin: 10px 0; }
      .promoter-phone {
        font-size: 12px;
        letter-spacing: 1.05px; }
      .spent-scanbits {
        color: $color-silver-chalice;
        font-family: var(--app-font-family);
        font-size: 16px;
        letter-spacing: -0.44px;
        line-height: 22px;
        text-align: right; } }
    &__row-bottom {
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      margin-top: 10px;
      max-width: 300px;
      button {
        height: 35px;
        width: 130px; } } } }

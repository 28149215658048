.ReactTable {
  border: none !important;
  border-radius: 10px;
  box-shadow: $table-shadow;
  background: $color-light;

  .rt-thead {
    padding: 0 20px;

    &.-header {
      box-shadow: none !important;

      .rt-tr {
        border-bottom: 1px solid $color-seashell;

        .rt-th {
          padding: 22px 0;

          border-right: none;

          text-align: left;
          letter-spacing: -0.44px;
          font-family: var(--app-font-family);
          font-size: 16px;
          color: $color-silver-chalice; } } }

    .rt-resizable-header:last-child {
      overflow: initial; } }

  .rt-tbody {
    padding: 0 20px;

    .rt-tr-group {
      flex: 0;

      .rt-td {
        padding: 20px 0;
        box-sizing: border-box;
        border-right: none !important;

        letter-spacing: -0.44px;
        font-family: var(--app-font-family);
        font-size: 16px;
        color: $color-ship-gray;
        p {
          overflow: hidden;
          text-overflow: ellipsis; } } } }

  .rt-noData {
    display: none; } }

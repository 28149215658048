@import "../../../style/index";

.chart-info {
  display: block;
  padding: 0 16px;
  @media all and (max-width: 625px) {
    padding: 0 10px; }
  .chart-item {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 10px;
    text-align: left;

    &__wrapper {
      width: 248px;
      margin-bottom: 10px;

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      @media all and (max-width: 625px) {
        width: 100%; } }

    &__label {
      position: relative; }

    &__indicator {
      width: 10px;
      height: 10px;

      top: calc(50% - 5px);
      position: absolute;
      border-radius: 10px; }

    &__name {
      margin: 0 0 0 25px;

      display: inline-block;

      text-align: left;
      font-family: var(--app-font-family);
      font-size: 16px;
      color: $color-ship-gray; }

    &__team-name {
      padding-left: 28px;
      margin: 0;

      letter-spacing: -0.39px;
      font-family: var(--app-font-family);
      font-size: 14px;
      color: $color-ship-gray; }

    &__device-name {
      padding-left: 28px;
      margin: 0;

      letter-spacing: -0.33px;
      font-family: var(--app-font-family);
      font-size: 12px;
      color: $color-gray; }

    &__value {
      @extend .chart-item__name;

      font-weight: bold; } } }

.radiobutton {
  display: block;
  cursor: pointer;
  user-select: none;
  text-align: left;
  & + .radio {
    margin-top: 12px; }
  &__input {
    display: none;
    & + span {
      display: inline-block;
      position: relative;
      padding-left: 30px;
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 1px;
        left: 0px;
        border-radius: 50%;
        margin-right: 5px;
        width: 16px;
        height: 16px;
        border: 1px solid #ccc;
        background: #fff; }
      &:after {
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        background: #222;
        position: absolute;
        border-radius: 50%;
        top: 5px;
        left: 4px;
        opacity: 0;
        transform: scale(0,0);
        transition: all .2s cubic-bezier(0.64, 0.57, 0.67, 1.53); } }
    &:checked + span:after {
      opacity: 1;
      transform: scale(1,1); } } }

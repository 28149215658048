.email-modal {
  text-align: left;
  font-size: 16px;

  &__title {
    font-size: 18px;
    text-align: center; }

  &__info {
    display: flex;
    margin-bottom: 20px;

    & > div:first-child {
      flex-grow: 1; }

    p {
      margin: 0 0 10px; } } }

@import "../../../../../style/index.sass";

.new-ticket-action {
    font-family: var(--app-font-family);
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-bottom: 30px;

    button {
      svg {
        display: initial; } }

    .page-title {
        &:first-child {
            margin: 0 0 30px 0; } }

    input {
        max-width: 300px;
        margin-bottom: 20px; }
    .custom-select {
        max-width: 300px;
        margin-bottom: 20px; }
    & > .seats-io-link {
        color: var(--primary-color);
        font-size: 18px;
        line-height: 24px;
        text-decoration: underline;
        padding: 0;
        margin: 0;

        & ~ .seats-io-link {
            margin: 0 0 23px 0; } }

    &__download {

        & > button {
            height: auto; }

        button {
            color: var(--primary-color);
            font-size: 18px;
            line-height: 24px;
            text-decoration: underline;
            background: none;
            text-align: left;
            border: none;
            cursor: pointer;
            padding: 0; } }

    &__submit {
        align-self: flex-end;
        width: 150px;
        margin-top: 30px; }

    &__de-restrict {
        margin: 30px 0; }

    &__de-restrict {
        align-self: flex-end;
        width: 186px; }

    &__choose-file {
        position: relative;
        max-width: 641px;

        & > p {
            color: $color-ship-gray;
            font-size: 20px;
            font-weight: bold;
            line-height: 27px;
            margin-top: 0; } }

    .btn-secondary {
        padding: 0 24px; }

    &__upload {
        flex: 1; }

    &__download-file-icon {
      margin-right: 5px;
      width: 24px;
      padding: 0;
      border: none;
      background: transparent;
      cursor: pointer; } }

.new-generate-pdfs {
    height: auto;

    &__action {
        display: flex;
        gap: 20px;

        & > button:first-child {
          margin-right: auto; } }

    .ReactTable {
        margin-top: 30px;

        .rt-tbody .rt-td {
          display: flex;
          align-items: center; } } }

.event-action-selector {
    margin-top: 20px;
    margin-bottom: 80px; }

.download-actions-container {
    display: flex;
    flex-direction: row;
    column-gap: 40px;
    margin-bottom: 20px; }

.download-action {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 5px;
    cursor: pointer;

    button {
      color: $color-text-dark;
      font-size: 16px;
      line-height: 24px;
      text-decoration: underline;
      background: none;
      text-align: left;
      border: none;
      cursor: pointer;
      padding: 0; } }

.bottom-buttons-container {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between; }

@import "../../../style/index";

.event {
  display: flex;
  min-height: calc(100vh - 104px);
  flex-direction: column;

  .Dropzone {
    border-radius: 12px;
    border: 2px solid $color-seashell;
    padding: 0;
    margin-bottom: 20px;
    overflow: hidden;
    height: 300px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    align-items: center;
    justify-content: center; }

  .empty-block {
    display: flex;
    flex-direction: column;
    padding: 70px 0;
    img {
      margin: 0 auto;
      width: 400px;
      height: 169px;
      @media (max-width: 767px) {
        width: 280px;
        height: auto; } }
    &__text {
      text-align: center;
      color: $color-ship-gray;
      font-family: var(--app-font-family);
      font-weight: 400;
      font-size: 20px;
      line-height: 23px;
      margin-bottom: 0px;
      font-weight: bold;
      position: relative; } }

  .link-stats {
    display: flex;
    gap: 24px;
    justify-content: end;
    padding-right: 48px;
    &:hover {
      cursor: pointer; }

    svg {
      width: 14px;
      margin-right: 8px;
      vertical-align: middle; } }

  button {
    svg {
      display: none; } }
  .page-title--not-absolute {
    display: flex;
    justify-content: space-between; }
  .page-title {
    margin-top: 40px;
    background-color: inherit;

    &:first-child {
      margin: 0; }

    a {
      text-decoration: none;
      color: inherit;
      margin: 36px 0; } }

  .btn-primary {
    &:before {
      content: '';
      position: absolute;
      height: 18px;
      width: 18px;
      left: 13px;
      top: 11px;
      background: transparent; }

    &--save {
      padding: 0;
      font-size: 18px;
      font-weight: 600;
      line-height: 22px; }

    &--import {
      &::before {
        background: url('/images/icons/ic_tickets.svg') no-repeat center; } }

    &--barcode {
      margin: 0;
      padding: 0 20px; }

    &--sell {
      width: 180px; }

    &--manage {
      width: 211px; }

    &--reselling {
      padding-right: 32px;
      padding-left: 32px; } }

  .ReactTable {
    .rt-table {
      padding: 10px 0px; }

    &.scroll {
      .rt-tbody {
        height: 516px;
        overflow-y: auto; } }

    .rt-th {
      padding-top: 8px !important;
      padding-bottom: 30px !important;

      overflow: visible;

      &:nth-child(2) {
        width: 400px !important; } }

    .rt-tbody {
      padding: 0;
      overflow: hidden;

      & .rt-td:nth-child(n + 2) {
        margin-right: 0 !important;

        &:nth-child(2) {
          width: 400px !important; } }

      & .rt-td:last-child() {
        display: flex;
        justify-content: flex-end;
        padding-right: 48px; }

      .rt-tr-group {
        cursor: pointer;
        padding: 0 20px;
        transition: all 0.2s linear;

        &:hover {
          background-color: var(--primary-light-color); } } } }

  .page-footer {
    margin-top: auto;
    button {
      height: 45px;
      min-width: 150px;
      margin-left: 19px;
      &:nth-child(1) {
        margin-left: 0; } }

    .checkbox {
      width: 190px; } }

  &__list {
    display: none; }

  @media all and (max-width: 767px) {
    .ReactTable {
      display: none; }
    &__list {
      display: block;
      &__item {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        border-top: 1px solid $color-seashell;
        &__title {
          color: $color-ship-gray;
          font-family: var(--app-font-family);
          font-size: 16px;
          font-weight: 600;
          letter-spacing: -0.44px;
          line-height: 22px;
          overflow: hidden;
          text-overflow: ellipsis;
          &:hover {
            cursor: pointer; } }
        &__counts {
          color: $color-silver-chalice;
          font-family: var(--app-font-family);
          font-size: 16px;
          letter-spacing: -0.44px;
          line-height: 22px;
          text-align: right;
          /*margin-right: 60px*/ }
        &__import {
          font-family: var(--app-font-family);
          position: absolute;
          z-index: 1;
          font-size: 12px;
          right: 20px;
          color: #ABABAB;
          text-decoration: underline;
          &:hover {
            cursor: pointer; } } } }

    .page-footer {
      display: flex;
      align-items: center;
      justify-content: center;
      button {
        width: 35px!important;
        height: 35px!important;
        min-width: 0!important;
        border-radius: 50%;
        margin-left: 40px;
        &:nth-child(1) {
          margin-left: 40px;
          position: relative;
          order: 2;
          svg {
            position: absolute;
            top: 50%;
            left: 50%;
            margin-left: -10px;
            margin-top: -10px; } }
        &:nth-child(2) {
          position: relative;
          order: 1;
          svg {
            position: absolute;
            top: 50%;
            left: 50%;
            margin-left: -10px;
            margin-top: -10px; } }
        &:nth-child(3) {
          display: flex;
          justify-content: center;
          align-items: center;
          order: 3;
          svg {
            g {}
            fill: var(--primary-color);
            transition: linear .2s; }
          &:disabled {
            svg {
              g {}
              fill: $color-silver-chalice; } } }

        svg {
          display: block;
          width: 19px;
          height: 19px;
          margin: 0; }
        span {
          display: none; } } } }

  .date-wrapper {
    position: absolute;

    display: flex;
    justify-content: space-between;

    svg {
      g#Assets {
        g {} }
      fill: var(--primary-color); }

    &__input-wrapper {
      height: 20px;

      display: flex;

      &:not(:last-child) {
        margin-right: 20px; } }

    .react-datepicker-wrapper {
      input {
        height: 20px;
        padding: 0;
        margin-left: 10px;

        border: none;
        border-bottom: 1px solid var(--primary-color);
        border-radius: 0;

        font-size: 10px;

        &::-webkit-input-placeholder {
          font-size: 12px;
          color: $color-ship-gray; }

        &::-moz-placeholder {
          font-size: 12px;
          color: $color-ship-gray; }

        &:-moz-placeholder {
          font-size: 12px;
          color: $color-ship-gray; }

        &:-ms-input-placeholder {
          font-size: 12px;
          color: $color-ship-gray; } } } }

  .react-datepicker-popper {
    z-index: 10; }

  .download-report {
    display: flex;
    &__button {
      border: none;
      margin-left: 5px;
      background: none;
      letter-spacing: -0.44px;
      text-decoration: underline;
      font-family: var(--app-font-family);
      font-size: 16px;
      color: var(--primary-color);
      padding: 0;

      &:hover {
        cursor: pointer; } }

    &__wrapper {
      display: flex;
      align-items: center;
      &:not(:last-child) {
        margin-right: 10px; }

      &:hover {
        cursor: pointer; } } } }

.add-event__block {
  .counter {
    width: 306px; } }

.all-and-scanned {
  span {
    height: 46px; }

  @media all and (min-width: 768px) and (max-width: 989px) {
    .all-and-scanned {
      display: flex;
      & .add-event__column {
        min-width: 288px;
        justify-content: space-between; }
      & .add-event__block {
        margin: 0; } }

    .add-event__row {
      flex-direction: column; }

    .add-event__row.table {
      flex-direction: row;
      & > div:first-child() {
        margin-right: 50px; } } } }

.select-email-templates-block {
  border-top: 2px solid $color-silver-chalice;
  border-bottom: 2px solid $color-silver-chalice;
  margin-bottom: 16px;
  padding-top: 16px; }

@import "../themes";

.btn-primary {
  box-sizing: border-box;
  background-color: var(--primary-color);
  border-width: 0px;
  border-radius: 5px;
  height: 44px;

  font-family: var(--app-font-family);
  font-weight: 600;
  font-size: 18px;
  color: $color-light;

  transition: linear .2s;

  &:disabled {
    background-color: transparent;
    border: 1px solid $color-silver-chalice;
    color: $color-silver-chalice;
    line-height: 22px;

    &:hover {
      cursor: not-allowed;
      box-shadow: none; } }

  &:hover {
    cursor: pointer;

    -webkit-box-shadow: 0px 0px 8px 0px var(--primary-color);
    -moz-box-shadow: 0px 0px 8px 0px var(--primary-color);
    box-shadow: 0px 0px 8px 0px var(--primary-color); } }

.btn-secondary {
  @extend .btn-primary;

  background-color: $color-gray;

  &:hover {
    -webkit-box-shadow: 0px 0px 8px 0px rgba(128, 128, 128, 1);
    -moz-box-shadow: 0px 0px 8px 0px rgba(128, 128, 128, 1);
    box-shadow: 0px 0px 8px 0px rgba(128, 128, 128, 1); } }

.btn-default {
  @extend .btn-primary;

  background-color: $color-light-gray;

  &:hover {
    -webkit-box-shadow: 0px 0px 8px 0px rgba(128, 128, 128, 1);
    -moz-box-shadow: 0px 0px 8px 0px rgba(128, 128, 128, 1);
    box-shadow: 0px 0px 8px 0px rgba(128, 128, 128, 1); } }

.btn-red {
  @extend .btn-primary;

  background: var(--reject-button-color);

  &:hover {
    -webkit-box-shadow: 0px 0px 8px 0px var(--reject-button-color);
    -moz-box-shadow: 0px 0px 8px 0px var(--reject-button-color);
    box-shadow: 0px 0px 8px 0px var(--reject-button-color); } }

.btn-red-outline {
  @extend .btn-primary;

  color: var(--reject-button-color);
  border-color: var(--reject-button-color);
  border-width: 1px;
  border-radius: 8px;
  background: none;

  &:hover {
    -webkit-box-shadow: 0px 0px 8px 0px var(--reject-button-color);
    -moz-box-shadow: 0px 0px 8px 0px var(--reject-button-color);
    box-shadow: 0px 0px 8px 0px var(--reject-button-color); } }

.btn-black {
  @extend .btn-primary;

  background: $color-ship-gray;

  &:hover {
    -webkit-box-shadow: 0px 0px 8px 0px rgba(63, 63, 65, 1);
    -moz-box-shadow: 0px 0px 8px 0px rgba(63, 63, 65, 1);
    box-shadow: 0px 0px 8px 0px rgba(63, 63, 65, 1); } }

.btn-green {
  @extend .btn-primary;

  background: $color-malachite;

  &:hover {
    -webkit-box-shadow: 0px 0px 8px 0px rgba(21, 193, 81, 1);
    -moz-box-shadow: 0px 0px 8px 0px rgba(21, 193, 81, 1);
    box-shadow: 0px 0px 8px 0px rgba(21, 193, 81, 1); } }

.btn-lavender {
  @extend .btn-primary;

  background: $color-lavender-bliss;

  &:hover {
    -webkit-box-shadow: 0px 0px 8px 0px $color-lavender-bliss;
    -moz-box-shadow: 0px 0px 8px 0px $color-lavender-bliss;
    box-shadow: 0px 0px 8px 0px $color-lavender-bliss; } }

.btn-primary-new {
  @extend .btn-primary;
  --color: #888;

  background-color: var(--color);

  &:hover {
    -webkit-box-shadow: 0px 0px 8px 0px var(--color);
    -moz-box-shadow: 0px 0px 8px 0px var(--color);
    box-shadow: 0px 0px 8px 0px var(--color); } }

.btn-primary-new.admin {
  --color: var(--primary-color); }

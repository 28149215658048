@import "../../../../../style/index.sass";

.event-ticket {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .page-title {
    margin-top: 0;
    margin-bottom: 0;
    a {
      text-decoration: none;
      margin: 36px 0;
      color: inherit; } }

  .rules {
    display: flex;
    flex-wrap: wrap;

    @media all and (max-width: 768px) {
      display: block; } }

  input {
    @include placeholder {
      font-size: 16px;
      letter-spacing: -0.44px;
      line-height: 22px; } }

  .date {
    color: $color-ship-gray;
    font-family: var(--app-font-family);
    font-size: 16px;
    letter-spacing: -0.44px;
    line-height: 22px;
    margin-bottom: 10px;
    margin-top: 0; }

  .add-event__column {
    flex-direction: row;
    max-width: 288px;

    .all-and-scanned {
      display: flex;
      margin-left: 110px; }

    @media all and (min-width: 341px) {
      min-width: 306px; } }

  .page-footer {
    margin-top: auto;
    padding: 20px 0;

    button {
      padding: 10px;
      margin-right: 20px;
      min-width: 150px;
      @media all and (max-width: 768px) {
        padding: 0px; }
      &:last-child {
        margin-right: 0; }
      svg {
        display: none; } } }
  @media all and (max-width: 989px) {
    .add-event {
      &__column {
        flex-wrap: nowrap; } } }

  @media all and (max-width: 840px) {
    .add-event__row {
      flex-wrap: nowrap; } }

  @media all and (max-width: 767px) {
    .add-event {
      &__column {
        flex-direction: column;
        max-width: 100%;
        flex-wrap: wrap; }
      &__row {
        display: block !important;

        & > div {
          width: 100%;
          display: block; } }
      &__block {
        &:nth-child(2) {
          margin-right: 0; } } }
    .page-footer {
      justify-content: center;
      button {
        min-width: 0;
        width: 35px !important;
        height: 35px !important;
        min-width: 0 !important;
        border-radius: 50%;
        margin-left: 40px;
        margin-right: 0 !important;
        &:nth-child(1) {
          display: flex;
          align-items: center;
          justify-content: center;
          order: 2; }
        &:nth-child(2) {
          margin-left: 0 !important;
          display: flex;
          align-items: center;
          justify-content: center;
          order: 1;
          svg {
            width: 22px;
            height: 22px; } }
        &:nth-child(3) {
          display: flex;
          align-items: center;
          justify-content: center;
          order: 3; }
        &:nth-child(4) {
          display: flex;
          align-items: center;
          justify-content: center;
          order: 4; }
        &.upload {
          display: none; }
        span {
          display: none; }
        svg {
          display: block;
          width: 19px;
          height: 19px;
          margin: 0;
          position: relative; } } } }

  & .all-and-scanned {
    justify-content: flex-start;
    & .add-event__column {
      justify-content: space-between;
      & > div:nth-child(2) {
        margin-left: 0; } }
    @media all and (min-width: 768px) and (max-width: 836px) {
      justify-content: flex-end; } }

  @media all and (min-width: 768px) {
    & .add-event__row.ticket-detail {
      flex-direction: row;
      @media all and (max-width: 838px) {
        & .scan-counter {
          margin-left: 86px; } }
      & .add-event__column {
        margin-right: 0px; }
      @media all and (min-width: 839px) {
        .scan-counter {
          margin-left: 157px; } } } } }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

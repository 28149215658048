$fs-sinclair-trial-font-path: "/fonts/FSSinclairTrial";
$poppins-font-path: "/fonts/Poppins";

@font-face {
    src: url("#{$fs-sinclair-trial-font-path}/FSSinclairTrial-Regular.otf") format("opentype");

    font-family: "FS Sinclair Trial";
    font-weight: 400; }

@font-face {
    src: url($fs-sinclair-trial-font-path + "/FSSinclairTrial-Medium.otf") format("opentype");

    font-family: "FS Sinclair Trial";
    font-weight: 500; }

@font-face {
    src: url($fs-sinclair-trial-font-path + "/FSSinclairTrial-Bold.otf") format("opentype");

    font-family: "FS Sinclair Trial";
    font-weight: 700; }

@font-face {
    src: url("#{$poppins-font-path}/Poppins-Regular.otf") format("opentype");

    font-family: "Poppins";
    font-weight: 400; }

@font-face {
    src: url($poppins-font-path + "/Poppins-Medium.otf") format("opentype");

    font-family: "Poppins";
    font-weight: 500; }

@font-face {
    src: url($poppins-font-path + "/Poppins-Bold.otf") format("opentype");

    font-family: "Poppins";
    font-weight: 700; }

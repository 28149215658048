@import "../../../../../style/index";

.reports {
  &__button {
    border: none;
    margin-left: 5px;
    background: none;
    letter-spacing: -0.44px;
    text-decoration: underline;
    font-family: var(--app-font-family);
    font-size: 18px;
    color: $color-text-dark;
    padding: 0;

    &:hover {
      cursor: pointer; } }

  &__wrapper {
    margin-bottom: 20px;
    width: max-content;
    display: flex;
    align-items: center;
    &:not(:last-child) {
      margin-right: 10px; }

    &:hover {
      cursor: pointer;
      svg {
          g {
            fill: var(--secondary-color); } }
      .reports__button {
        color: var(--secondary-color); } } }

  .all-and-scanned {
    margin: 40px 0;
    padding: 40px 0 5px;
    border-top: 2px solid $color-seashell;
    border-bottom: 2px solid $color-seashell; } }

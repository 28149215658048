@import "../../style/index";

a {
  text-decoration: none;
  display: inline-block;
  margin: 36px 0 0 0; }

.scanbits {
  min-height: 44px;
  width: 197px;
  position: relative;
  margin-top: 6px;

  &__body {
    width: 100%;
    height: 44px;
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #FFFFFF;
    color: var(--primary-color);
    font-family: var(--app-font-family);
    font-size: 20px;
    line-height: 27px;
    border-radius: 5px;

    svg {
      margin-right: 10px; } }

  &__shadow {
    height: 26px;
    width: 191px;
    position: absolute;
    bottom: 0;
    left: 3px;
    background: transparent;

    box-shadow: $table-shadow; } }

@import "../../../../../style/index";

.statistic-chart {
  display: block;
  @media all and (max-width: 625px) {
    display: none; }
  &_mobile {
    display: none;
    @media all and (max-width: 625px) {
      display: block; } } }

.statistics {
  position: relative;
  height: auto;
  padding: 0 0 10px;
  flex-grow: 1;

  &__tabs {
    @extend .statistics__chart-tabs;

    text-align: left !important;

    .react-tabs {
      &__tab-list {
        border-bottom: none !important;

        @media all and (max-width: 768px) {
          display: flex !important; } }

      &__tab {
        text-align: center;

        &:focus {
          box-shadow: none;

          &:after {
            display: none; } }

        @media all and (max-width: 768px) {
          width: auto !important;

          flex: 1 !important; } } } }

  .chart-wrapper {
    position: relative;

    button {
      svg {
        display: initial; } }

    .day-picker {
      z-index: 2;
      position: absolute;
      top: 25px;
      left: 0;
      right: 330px;
      max-width: 754px;
      display: flex;
      align-items: center;
      justify-content: center;

      letter-spacing: -0.5px;
      font-family: var(--app-font-family);
      font-size: 16px;
      color: $color-ship-gray;
      @media all and (max-width: 768px) {
        display: flex;
        justify-content: start;
        right: 0;
        left: 110px;
        width: 250px; }
      @media all and (max-width: 660px) {
        left: 70px; }
      @media all and (max-width: 625px) {
        display: flex;
        left: 0px;
        top: 15px;
        width: 100%;
        justify-content: space-around; }
      &-date {
        padding: 0; }

      .btn-default {
        width: 35px;
        height: 35px;
        padding: 0;

        background-color: $color-ship-gray;
        @media all and (max-width: 625px) {
          background-color: #808080; }
        &.disabled {
          visibility: hidden; }

        &.prev {
          margin-right: 44px;
          transform: rotate(90deg);
          @media all and (max-width: 625px) {
            box-shadow: none;
            &:focus {
              box-shadow: none; } } }
        &.next {
          margin-left: 44px;
          transform: rotate(-90deg);
          @media all and (max-width: 625px) {
            box-shadow: none;
            &:focus {
              box-shadow: none; } } } } } }

  .recharts-wrapper {
    display: flex;
    width: 100% !important;
    font-family: var(--app-font-family);

    .recharts-surface {
      background-color: $color-light;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 2px -2px rgba(0, 0, 0, 0.12), 0 2px 5px 0 rgba(0, 0, 0, 0.2);
      @media all and (max-width: 426px) {
        height: 325px; }
      .recharts-cartesian-axis-tick {
        font-family: var(--app-font-family);
        color: $color-ship-gray; } } }

  .recharts-tooltip-wrapper {
    height: inherit;
    margin-left: 50px;
    font-family: inherit;
    position: absolute;
    right: 0;
    top: auto !important;

    pointer-events: auto !important;
    visibility: visible !important;
    transform: none !important;

    background-color: $color-light;
    @media all and (max-width: 625px) {
      margin-left: 0px;
      bottom: -400px;
      left: 0; }
    @media all and (max-width: 426px) {
      bottom: -365px; }

    .recharts-default-tooltip {
      border: none !important;
      background-color: inherit !important; }

    .react-tabs__tab-panel {
      height: 345px; } }

  &__chart-tabs {
    padding: 0px;
    text-align: center;

    &.tablist {
      padding-bottom: 22px;
      width: 400px;
      @media all and (max-width: 426px) {
        padding-bottom: 0px; } }
    .react-tabs {
      &__tab-list {
        padding-bottom: 22px;
        width: 280px;
        border-bottom: 1px solid $color-seashell;
        @media all and (max-width: 625px) {
          padding-bottom: 18px;
          padding-top: 0px;
          width: 100%;
          height: 50px; } }

      &__tab {
        padding: 9px 18px;
        margin-right: -1px;

        border-radius: 0;
        border: 1px solid rgba(0, 0, 0, 0.12);

        font-family: var(--app-font-family);
        font-size: 16px;
        color: $color-gray;
        @media all and (max-width: 625px) {
          padding: 12px 18px; }
        &:first-child {
          border-radius: 16px 0 0 16px; }

        &:last-child {
          border-radius: 0 16px 16px 0; }
        &--selected {
          border-color: var(--primary-color);

          color: var(--primary-color); } } } } }
.tooltip .react-tabs__tab-panel--selected {
  height: 515px;
  overflow: auto; }

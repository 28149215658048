@import "../../../style/index";

.registration {
  text-align: center;

  @include respond-to('small-max') {
    padding: 20px;

    .form-error {
      &.xs-hidden {
        display: none; } } }

  @media (max-width: 812px) and (orientation: landscape) {
    padding: 20px 0; }

  &__contact-details {
    transition: linear .5s;

    position: fixed;
    left: 50%;

    transform: translate(-50%, -50%);

    @include respond-to('small-max') {
      width: 90%;
      margin: 0 auto;
      left: auto;

      position: relative;
      transform: none; }

    @media (max-width: 812px) and (orientation: landscape) {
      width: 90%;
      margin: 0 auto;
      left: auto;

      position: relative;
      transform: none; }

    &.visible {
      top: 50%;
      visibility: visible; }

    &.hidden {
      top: -100%;
      visibility: hidden;

      @include respond-to('small-max') {
        visibility: visible; }

      @media (max-width: 812px) and (orientation: landscape) {
        visibility: visible; } } }

  &__company-details {
    transition: linear .5s;

    position: fixed;
    left: 50%;

    transform: translate(-50%, -50%);

    @include respond-to('small-max') {
      width: 90%;

      margin: 0 auto;
      left: auto;

      position: relative;
      transform: none; }

    @media (max-width: 812px) and (orientation: landscape) {
      width: 90%;

      margin: 0 auto;
      left: auto;

      position: relative;
      transform: none; }

    &.visible {
      top: 50%;
      visibility: visible; }

    &.hidden {
      top: 150%;
      visibility: hidden;

      @include respond-to('small-max') {
        visibility: visible; }

      @media (max-width: 812px) and (orientation: landscape) {
        visibility: visible; } }

    .button-prev {
      margin-bottom: 50px; }

    .auth__title {
      @include respond-to('small-max') {
        display: none; } } }

  .accept-terms {
    margin-bottom: 20px;

    .checkbox {
      label {
        letter-spacing: -0.35px;
        font-family: var(--app-font-family);
        font-weight: 600;
        font-size: 14px;
        width: 100%;
        & span {
          padding-left: 5px; } } } }

  &__sign-in {
    letter-spacing: -0.35px;

    font-family: var(--app-font-family);
    font-weight: 600;
    font-size: 14px;
    color: $color-ship-gray;

    position: fixed;
    bottom: 40px;
    left: 50%;

    transform: translateX(-50%);

    @include respond-to('small-max') {
      margin-top: 20px;

      position: inherit;
      transform: none; }

    @media only (min-width: 768px) and (max-width: 1440px) and (orientation: landscape) {
      right: 0;
      bottom: 20px;
      left: auto; }

    @media (min-height: 768px) and (max-height: 900px) {
      right: 0;
      bottom: 20px;
      left: auto; }

    @media (min-height: 750px) and (max-height: 850px) {
      right: 0;
      bottom: 20px;
      left: auto; }

    @media (max-width: 812px) and (orientation: landscape) {
      margin-top: 20px;

      position: inherit;
      transform: none; }

    a {
      @extend .registration__sign-in;

      position: initial;
      @media (max-width: 812px) {
        margin-left: 5px; }
      @media (min-width: 813px) {
        margin-left: 25px; }

      &:hover {
        text-decoration: none; } } } }

@import "../../../../../style/index";

.event-transactions {
    padding-bottom: 30px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    &__loader {
      position: relative;
      flex-grow: 1; }

    .seasonpass__select__menu {
        z-index: 4; }

    .searchPanel {
        display: flex;
        margin-bottom: 44px;
        align-items: center;
        gap: 30px;
        height: 46px;

        .checkbox {
            margin: 0;

            input {} }

        &__select {
          font-family: var(--app-font-family);
          width: 220px; }


        &__search {
          width: 300px; }

        button {
          margin-left: auto;
          padding: 10px 30px;
          flex-shrink: 0; } }

    .payment-status {
        display: flex;
        gap: 3px;
        cursor: pointer; }

    .buttons-block {
        display: flex;
        gap: 14px;
        padding: 0px 10px;

        &.options {
            padding: 7px 24px; }

        .IconButton {
            height: 35px;
            width: 35px;
            border-radius: 5px;
            padding: 0;
            font-size: 12px;

            .IconButton {
              height: 33px;
              width: 33px;
              &.primary, &.red, &.green, &.gray, &.lavender {
                border: none; } }

            &.red {
                border: 1px solid var(--reject-button-color);
                background-color: $color-light; }
            &.green {
                border: 1px solid $color-malachite;
                background-color: $color-light; }
            &.primary {
                border: 1px solid var(--primary-color);
                background-color: $color-light; }
            &.gray {
                border: 1px solid $color-silver-chalice;
                background-color: $color-light; }
            &.lavender {
                border: 1px solid $color-lavender-bliss;
                background-color: $color-light; }

            svg {
                vertical-align: middle;
                display: initial; } } }

    .ReactTable {
        height: calc(100vh - 270px);
        overflow: hidden;

        .rt-table {
            padding: 0 0 0 20px; }

        .rt-thead {
            padding: 0;
            &.-header {
                .rt-tr {
                    .rt-th {
                        padding: 20px 5px;
                        display: flex;
                        align-items: center;
                        transition: linear .07s; } } }

            .rt-resizable-header:last-child {
                overflow: hidden; } }

        .rt-tbody {
            padding: 0;
            box-sizing: content-box;
            flex: 99999 1 auto;

            .rt-tr-group {
                display: block;

                .rt-td:last-child {
                    position: sticky;
                    z-index: 1; }

                .rt-td {
                    overflow: hidden;
                    padding: 20px 5px;

                    .td-content {
                      overflow: hidden;
                      text-overflow: ellipsis; }

                    ol {
                      margin: 0;
                      padding-left: 10px; }

                    ul {
                        display: flex;
                        flex-direction: column;
                        margin: 0;
                        padding: 0;
                        list-style: none;
                        gap: 19px; }

                    .accordion-btn {
                        transition: none;
                        &.disable {
                            cursor: default;
                            &:hover {
                                box-shadow: none; } } } } }

            .rt-tr {
                display: flex;
                max-height: 68px;

                &.active {
                    max-height: none;
                    align-items: flex-start;
                    .accordion-btn {
                        svg {
                            transform: rotate(180deg); } } } } } }

    .react-contextmenu {
        border-radius: 5px;
        background: $color-light;
        box-shadow: 0px 1px 10px 0px #5F5C601F; }

    .react-contextmenu-item {
        padding: 6px 12px;
        font-size: 18px;
        color: $color-ship-gray;
        &:hover {
            background: var(--primary-light-color); } } }

@import "../../style/index";

.transactions {
    .seasonpass__select__menu {
        z-index: 4; }

    button {
      svg {
        display: initial; } }

    .searchPanel {
        display: flex;
        margin-bottom: 20px;
        justify-content: center;
        align-items: center;
        gap: 30px;
        height: 46px;

        .checkbox {
            margin: 0; }

        &__select {
            width: 256px;
            font-family: var(--app-font-family); }

        &__search {
            width: 418px; } }

    .payment-status {
        display: flex;
        gap: 3px;
        cursor: pointer; }

    .buttons-block {
        display: flex;
        gap: 14px;

        &.options {
            padding: 7px 24px; }

        .IconButton {
            height: 35px;
            width: 35px;
            border-radius: 5px;
            padding: 0;
            font-size: 12px;

            &.red {
                border: 1px solid var(--reject-button-color);
                background-color: $color-light; }
            &.green {
                border: 1px solid $color-malachite;
                background-color: $color-light; }
            &.primary {
                border: 1px solid var(--primary-color);
                background-color: $color-light; }
            &.gray {
                border: 1px solid $color-silver-chalice;
                background-color: $color-light; }
            &.lavender {
                border: 1px solid $color-lavender-bliss;
                background-color: $color-light; }

            svg {
                vertical-align: middle; } } }

    .ReactTable {
        height: calc(100vh - 250px);
        overflow: hidden;

        .rt-table {
            padding: 0 0 0 20px; }

        .rt-thead {
            padding: 0;
            &.-header {
                .rt-tr {
                    .rt-th {
                        display: flex;
                        align-items: center;
                        padding: 6px 0;
                        transition: linear .07s; } } }

            .rt-resizable-header:last-child {
                overflow: hidden; } }

        .rt-tbody {
            padding: 0;
            box-sizing: content-box;
            flex: 99999 1 auto; }

        .rt-tr-group {
            display: block;

            .rt-td:last-child {
                position: sticky;
                z-index: 1; }

            .rt-td {
                overflow: hidden;

                ul {
                    display: flex;
                    flex-direction: column;
                    margin: 0;
                    padding: 0;
                    list-style: none;
                    gap: 19px; }

                .accordion-btn {
                    transition: none;
                    &.disable {
                        cursor: default;
                        &:hover {
                            box-shadow: none; } } } }

            .rt-tr {
                display: flex;
                max-height: 68px;

                &.active {
                    max-height: none;
                    align-items: flex-start;
                    .accordion-btn {
                        svg {
                            transform: rotate(180deg); } } } } } }

    .react-contextmenu {
        border-radius: 5px;
        background: $color-light;
        box-shadow: 0px 1px 10px 0px #5F5C601F; }

    .react-contextmenu-item {
        padding: 6px 12px;
        font-size: 18px;
        color: $color-ship-gray;
        &:hover {
            background: var(--primary-light-color); } } }

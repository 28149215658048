@import "../../../style/index.sass";

.Dropzone {
  padding: 40px 10px;

  &__img {
    display: block;
    object-fit: cover;
    width: 100%; }

  p {
    font-family: var(--app-font-family);
    text-align: center; }

  input {
    display: none; }

  button {
    display: block;
    margin: 0 auto; } }

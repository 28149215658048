@import "../../../style/index";

.statistics__table {
    .ReactTable {
        box-shadow: none;
        border-radius: 5px;
        border: 1px solid $color-seashell !important;
        .rt-th, .rt-td {
            box-sizing: border-box;
            padding-right: 20px !important;
            padding-left: 20px !important; }
        .rt-thead {
            padding: 0; }
        .rt-tbody {
            padding: 0; }
        .rt-tfoot {
            box-shadow: none;
            .rt-td {
                padding: 20px;
                border: none;
                background-color: $color-light; } } } }

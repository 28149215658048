@import "../../style/index";

.container {
    border: 2px solid $color-silver-chalice;
    border-radius: 8px;
    margin: 8px 0px;
    padding: 1rem;
    position: relative;

    .label {
        background-color: white;
        position: absolute;
        z-index: 1;
        top: -10px;
        left: 1rem;
        color: $color-silver-chalice; } }

@import "../../style/index";

.stepper {
    &__header {
        display: flex;
        &-hero {
            display: flex;
            align-items: center;
            h5 {
                margin-left: 5px;
                font-size: 12px;
                font-weight: 400;
                font-family: var(--app-font-family); } }
        &_active {}

        &-title {
            flex: 1 0;
            &:not(:last-child) {
                margin-right: 5px; } }
        &-indicator {
            height: 4px;
            border-radius: 4px;
            background-color: #E0E6FF;
            margin-bottom: 8px;
            &_active {
                background: var(--primary-color); } } } }

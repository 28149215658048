[data-theme="fanceetickets"] {
  --app-font-family: "FS Sinclair Trial", "Open Sans", "Helvetica Neue", sans-serif;
  --primary-color: #6071B5;
  --primary-semi-light-color: #C0C7E2;
  --primary-light-color: #F2F4FE;
  --secondary-color: #6071B5;
  --secondary-light-color: #959fc5;
  --reject-button-color: #ED1343; }

[data-theme="sixtix"] {
  --app-font-family: "FS Sinclair Trial", "Open Sans", "Helvetica Neue", sans-serif;
  --primary-color: #E4547C;
  --primary-semi-light-color: #F7C8D2;
  --primary-light-color: #fce3e8;
  --secondary-color: #FB896B;
  --secondary-light-color: #FFEBE3;
  --reject-button-color: #E4547C; }

[data-theme="france"] {
  --app-font-family: "Poppins", Arial;
  --primary-color: #073B42;
  --primary-semi-light-color: #74C1D6;
  --primary-light-color: #E2F2F6;
  --secondary-color: #1F7A8C;
  --secondary-light-color: #C9DAE0;
  --reject-button-color: #D63420; }

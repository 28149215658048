.modal {
  position: fixed;
  z-index: 999;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); }

body.modal-opened {
  height: 100vh;
  overflow: hidden; }

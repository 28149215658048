@import "../../../style/index";

.forgot-password {
  display: flex;
  flex-direction: column;
  align-items: center;

  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);

  text-align: center;

  @media (max-width: 812px) and (orientation: landscape) {
    padding: 20px 0;
    top: auto;

    transform: translate(-50%, 0%); }

  form {
    @include respond-to('small-max') {
      width: 100%; } }

  &__buttons-wrapper {
    display: flex;
    flex-direction: row;

    @include respond-to('small-max') {
      flex-direction: column; }

    button {
      &:first-child {
        margin-right: 20px;

        @include respond-to('small-max') {
          margin-right: 0;
          margin-bottom: 20px; } } } }


  &__info {
    text-align: center;
    font-family: var(--app-font-family);
    font-size: 18px;
    color: $color-ship-gray;

    @include respond-to('small-max') {
        font-size: 16px; }

    a {
      @extend .forgot-password__info;

      text-decoration: underline;
      font-size: 18px !important;

      &:hover {
        text-decoration: none; } }

    .auth__link {
      margin-top: 20px;

      display: block;

      font-size: 14px !important; } }

  &__username {
    margin-bottom: 40px;

    letter-spacing: -0.44px;
    font-family: var(--app-font-family);
    font-size: 16px;
    color: $color-ship-gray;

    @include respond-to('small-max') {
      margin-bottom: 10px; } } }

@import "../../../style/index";

.membership-cards-container {
    margin: 0;
    margin-bottom: 28px;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 48px;
    justify-content: center; }

.membership-card {
    box-sizing: border-box;
    border-radius: 8px;
    background-color: $color-seashell;
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow: hidden;

    .season-pass-buyed {
        display: flex;
        height: 44px;
        flex: none;

        .season-pass-buyed-label {
            background: transparent;
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;
            border: 1px solid $color-seashell;
            background-color: $color-mint;
            border-width: 0px;
            border-radius: 5px 0px 0px 5px;
            margin: 0;
            font-family: var(--app-font-family);
            font-weight: 600;
            font-size: 18px;
            color: $color-light;
            text-align: center; }

        .season-pass-buyed-icon-button {
            width: 15%;
            margin: 0;
            padding: 0;
            border-radius: 0px 5px 5px 0px;
            border: none;

            .tooltip {
                margin: 0;
                padding: 0;
                width: 100%;
                height: 100%;
                display: grid;
                place-items: center;

                .season-pass-buyed-icon-button-icon {
                    transition: linear .2s;
                    transform: translate(-10%,0) scale(1); } } }

        .season-pass-buyed-icon-button[disabled] {
            border-left: 2px solid $color-seashell;
            background-color: $color-mint; } }

    .season-pass-buyed:hover {

        .season-pass-buyed-icon-button {
            width: 35%;

            .season-pass-buyed-icon-button-icon {
                transform: translate(-10%,0) scale(1.3); } } } }

.membership-card-edit {
    box-sizing: border-box;
    border-radius: 8px;
    background-color: $color-seashell;
    border: 2px solid $color-seashell;
    transition: border .2s;
    padding: 1rem;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;

    width: 20rem;
    aspect-ratio: 2/1;
    display: flex;
    flex-direction: column;
    justify-content: start;
    position: relative;

    p {
        margin: 0; }

    .footer {
        position: absolute;
        bottom: 1rem;
        display: flex;
        width: calc( 100% - 2rem );
        justify-content: space-between;
        align-items: center;
        margin: 0;

        .seat-label {
            margin: 0; }
        .edit-icon {
            margin-left: auto; } } }

.membership-card-edit.active:hover {
    border: 2px solid $color-partizan-primary;
    cursor: pointer; }

.ticket-and-price {
    display: flex;
    justify-content: space-between;
    flex: none;
    margin-bottom: 1.2rem;

    .price-label {
        text-align: end;
        width: 7rem;
        flex: none; } }

.membership-tickets-container {
    margin: 0;
    padding: 0;
    padding-top: 0;
    align-self: flex-start;
    width: 100%;
    color: $color-text-dark;

    ol {
        margin: 0;
        padding: 0;
        list-style: none; }

    .event-section {
        width: 100%;
        padding-bottom: 1rem;
        margin-bottom: 1rem;
        border-bottom: 2px solid $color-seashell;
        position: relative;

        .show-more-button {
            position: absolute;
            bottom: 3rem;
            left: 50%;
            transform: translate(-50%, 0);
            background-color: $color-partizan-primary; }

        .event-header {
            background-color: $color-text-dark;
            padding: 1rem 1rem;
            border-radius: 8px 8px 0px 0px;
            height: max-content;

            h3 {
                text-align: center;
                margin: 0;
                color: $color-light; } }

        .ticket-list-container {
            position: relative;
            overflow: hidden;
            transition: all 1s;
            border: 1px solid $color-text-dark;
            box-shadow: 0px 0px 0px -2px $color-silver-chalice inset;
            border-radius: 0px 0px 8px 8px;

            h4 {
                margin: 1rem;
                color: $color-text-dark; }

            .ticket-list {
                width: 100%;
                background-color: transparent;
                top: 0;
                left: 0;
                position: absolute;
                display: flex;
                justify-content: center;
                gap: 48px;
                flex-direction: row;
                flex-wrap: wrap;
                margin: 0;
                padding: 48px 0;
                list-style: none;
                z-index: 1;

                .ticket {
                    box-sizing: border-box;
                    border-radius: 8px;
                    background-color: $color-seashell;
                    border: 2px solid $color-seashell;
                    transition: border .2s;
                    padding: 1rem;

                    width: 20rem;
                    aspect-ratio: 2/1;
                    display: flex;
                    flex-direction: column;
                    justify-content: start;
                    position: relative;
                    overflow: hidden;

                    @include respond-to('small-max') {
                      width: 280px; }

                    p {
                        margin: 0; }

                    .resale-block {
                        position: absolute;
                        top: calc( 100% - 2rem);
                        left: 0;
                        width: 100%;
                        height: 8rem;
                        background-color: $color-light-blue;
                        display: flex;
                        flex-direction: column;
                        z-index: 10;
                        border-radius: 8px 8px 0px 0px;
                        overflow: hidden;
                        transition: all 1s;
                        box-shadow: 0px -2px 12px -6px $color-text-dark;

                        .resale-label {
                            display: grid;
                            place-items: center;
                            height: 2rem;
                            background-color: $color-light-blue;
                            user-select: none;

                            p {
                                width: 100%;
                                margin: 0;
                                text-align: center;
                                color: $color-ship-gray; } }

                        .resale-label.selling {
                            background-color: $color-creamy-vanilla; }

                        .resale-label.sold {
                            background-color: $color-minty-green; }

                        .resale-button {
                            user-select: none;
                            --color: #{$color-partizan-primary};
                            margin: 0px 16px; }

                        .resale-total-keeped {
                            color: $color-text-dark;
                            margin: 0.8rem 16px;
                            font-size: 16px; } }

                    .resale-block.hover {
                        top: calc( 100% - 8rem); } }

                .add-ticket-button {
                    box-sizing: border-box;
                    border-radius: 8px;
                    background-color: $color-seashell;
                    border: 2px solid $color-seashell;
                    transition: all .2s;
                    padding: 1rem;
                    border-bottom-left-radius: 2px;
                    border-bottom-right-radius: 2px;

                    width: 20rem;
                    aspect-ratio: 2/1;
                    cursor: pointer;
                    user-select: none;
                    font-size: 1.2rem;
                    color: $color-partizan-primary; }

                .add-ticket-button:hover {
                    border: 2px solid $color-partizan-primary; } } }

        .hided {
            box-shadow: 0px -4px 64px -4px $color-silver-chalice inset;
            .ticket-list {
                z-index: -1; } }

        button {
            padding-left: 2rem;
            padding-right: 2rem; } } }

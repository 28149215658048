@import "../../style/index";

.faq {
  @media all and (max-width: 768px) {
    .page-title {
      width: 100%;
      padding: 10px;
      margin: 0;

      position: fixed;
      top: 0;
      z-index: 2;
      box-sizing: border-box;

      text-align: center;
      font-size: 18px;
      font-weight: bold;
      display: block !important; } }

  .Collapsible {
    max-width: 752px;
    border-radius: 10px;
    background-color: $color-light;
    box-shadow: $table-shadow;
    margin-bottom: 10px;

    &__trigger {
      display: flex;
      align-items: center;
      justify-content: space-between;

      padding: 0;
      cursor: pointer;

      p {
        color: $color-ship-gray;
        font-family: var(--app-font-family);
        font-size: 18px;
        letter-spacing: -0.44px;
        line-height: 24px;
        padding-right: 10px; }

      .icon {
        height: 35px;
        width: 35px;
        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 3px;
        background-color: $color-gray; }

      &.is-open {
        .icon {
          transform: rotate(180deg); } } }

    &__contentInner {
      color: $color-ship-gray;
      font-family: var(--app-font-family);
      font-size: 16px;
      letter-spacing: -0.44px;
      line-height: 22px;
      padding: 20px 20px 0 0;
      p {
        margin: 0; } } }

  &__title {
    color: $color-silver-chalice;
    font-family: var(--app-font-family);
    font-size: 16px;
    letter-spacing: -0.44px;
    line-height: 22px;
    margin-bottom: 30px;
    font-weight: normal; } }

@import "../index.sass";

.admin-page {
    height: calc(100svh - 80px);
    padding: 0 20px;
 }    // overflow: auto

.admin-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;

    .page-title {
        margin-top: 0;
        margin-bottom: 0;
        a {
            text-decoration: none;
            margin: 36px 0;
            color: inherit; } }

    input {
        @include placeholder;
        font-size: 16px;
        letter-spacing: -0.44px;
        line-height: 22px; }

    .date {
        color: $color-ship-gray;
        font-family: var(--app-font-family);
        font-size: 16px;
        letter-spacing: -0.44px;
        line-height: 22px;
        margin-bottom: 10px;
        margin-top: 0; }

    &__column {
        flex-direction: row;
        max-width: 288px;

        @media all and (min-width: 341px) {
            min-width: 306px; } }

    &__row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 16px; }

    &__block {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        width: 306px;

        label {
            color: $color-silver-chalice;
            font-family: var(--app-font-family);
            font-size: 16px;
            letter-spacing: -0.44px;
            line-height: 22px;
            margin-bottom: 10px; }

        input {
            width: 306px;
            background: white;
            height: 46px;
            box-sizing: border-box;
            border-radius: 4px; } }

    .page-footer {
        margin-top: auto;
        padding: 20px 0;

        button {
            padding: 10px;
            margin-right: 20px;
            min-width: 150px; }
        @media all and (max-width: 768px) {
            padding: 0px; }
        &:last-child {
            margin-right: 0; }
        svg {
            display: none; } } }

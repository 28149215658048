@import "../../style/index";

.mobile-header {
  width: 100%;
  padding: 10px;
  padding-left: 50px;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  box-sizing: border-box;

  background-color: $color-light;

  text-align: center;
  font-size: 18px;
  font-weight: bold;
  display: block;
  font-family: var(--app-font-family);
  color: $color-ship-gray;

  @media all and (min-width: 768px) {
    display: none; } }

@import "../../../style/index";

.login {
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  text-align: center;

  @media (max-width: 767px) and (orientation: landscape) {
    padding: 20px 0; }

  @media (max-width: 812px) and (orientation: landscape) {
    height: auto;
    padding: 20px 0; }

  .btn-primary {
    margin-top: 40px;
    margin-bottom: 20px; }

  form {
    @include respond-to('small-max') {
      width: 90%; } }

  .mark {
    position: absolute;
    bottom: 0;
    left: 0;
    margin-left: 10px; } }

@import "../../style/index.sass";

.public-user-modal {
    @extend .edit-user-modal;

    padding: 20px 0;

    .swal2-content {
        text-align: left;
        padding: 0px 20px;
        max-height: max-content;
        color: $color-text-dark; }

    .swal2-label {
        color: $color-ship-gray; }

    .swal2-actions {
        .swal2-confirm {
            background-color: $color-partizan-primary !important;

            &:hover {
              -webkit-box-shadow: 0px 0px 8px 0px;
              -moz-box-shadow: 0px 0px 8px 0px $color-partizan-primary;
              box-shadow: 0px 0px 8px 0px $color-partizan-primary; } } }

    .swal2-flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 0;

        .IconButton {
            background: transparent;
            height: 40px;
            width: 40px;
            border: 1px solid $color-text-dark;
            display: grid;
            place-items: center; } }

    .swal2-flex+div {
        margin: 0 auto;
        width: calc( 100% - 40px ) !important; } }

.public-user-modal.popup-mode {
    width: 100% !important;
    max-width: 1058px; }

@import "../../../style/index";

.add-scan-team {
  display: flex;
  flex-direction: column;

  h4 {
    color: $color-silver-chalice;
    font-family: var(--app-font-family);
    font-size: 16px;
    letter-spacing: -0.44px;
    line-height: 22px;
    margin: 0 0 10px;
    font-weight: 400; }

  .page-title {
    margin: 0;
    a {
      color: inherit;
      text-decoration: none;
      margin: 36px 0; } }

  &__body {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @media(max-width: 1170px) {
      flex-wrap: wrap;
      justify-content: space-between; }
    @media(max-width: 625px) {
      flex-direction: column; } }

  .ticket-list {
    @media(max-width: 1170px) {
      order: 1; }
    @media(max-width: 625px) {
      order: 2; } }

  .UserInfoSettings {
    @media(max-width: 625px) {
      width: auto; } }

  .qr {
    margin-top: 54px;
    padding: 20px;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.18);

    display: flex;
    flex-direction: column;
    gap: 20px;

    @media(max-width: 1170px) {
      order: 2;
      margin-right: 40px; }
    @media(max-width: 625px) {
      order: 1; }

    img {
      max-width: 200px;
      margin: 25px 0; }

    svg {
      display: none; }

    .link-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;

      .qr-link {
        margin: 0 10px 0 0;
        cursor: pointer;
        color: var(--primary-color);
        font-family: var(--app-font-family);
        font-size: 18px;
        line-height: 24px; }

      .copy-app-link {
        padding: 0;

        border: none; }

      svg {
        &:hover {
          cursor: pointer; } } } }

  .page-footer {
    flex-direction: column;
    justify-content: space-between;
    margin-top: auto;
    flex-wrap: wrap;

    .checkboxes {
      display: flex;

      @media all and (max-width: 768px) {
        margin-bottom: 0; }

      &-item {
        color: $color-ship-gray;
        font-family: var(--app-font-family);
        font-size: 14px;
        letter-spacing: -0.35px;
        line-height: 19px;
        display: flex;
        width: 160px;
        height: 50px;

        @media all and (max-width: 768px) {
          width: 100%;

          &.custom-margin-left {
            margin-left: 0; } }

        .Checkbox {
          margin-right: 10px;
          align-items: center; }
        &:not(:first-child) {
          margin-left: 40px; } } }

    .buttons {
      text-align: right;

      @media all and (max-width: 768px) {
        text-align: center; }

      button {
        height: 45px;
        padding: 0 1em;
        margin-left: 20px;
        margin-top: 40px;

        svg {
          display: none; }
        &:nth-child(1) {
          margin-left: 0; } } } }

  @media all and (max-width: 768px) {
    .page-footer {
      flex-wrap: wrap;
      margin-top: 0;
      .checkboxes {
        order: 1;
        flex-wrap: wrap;
        .Checkbox {
          margin-bottom: 20px; } }
      .buttons {
        order: 3;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
        width: 100%;
        button {
          width: 35px !important;
          height: 35px !important;
          min-width: 0 !important;
          border-radius: 50%;
          margin-left: 40px;
          position: relative;
          &:nth-child(1) {
            order: 2;
            svg {
              position: absolute;
              width: 22px;
              height: 22px;
              top: 50%;
              left: 50%;
              margin-top: -11px;
              margin-left: -11px; }
            &:disabled {
              svg {
                g {}
                fill: $color-silver-chalice; } } }

          &:nth-child(2) {
            margin-left: 0;
            margin-right: 40px;
            order: 1;
            svg {
              position: absolute;
              width: 22px;
              height: 22px;
              top: 50%;
              left: 50%;
              margin-top: -11px;
              margin-left: -11px; } }
          &:nth-child(3) {
            order: 3;
            svg {
              position: absolute;
              width: 22px;
              height: 22px;
              top: 50%;
              left: 50%;
              margin-top: -11px;
              margin-left: -11px; }
            &:disabled {
              svg {
                g {}
                fill: $color-silver-chalice; } } }

          svg {
            display: block;
            width: 19px;
            height: 19px;
            margin: 0; }
          span {
            display: none; } } }

      .qr {
        order: 2;
        text-align: center;
        a {
          justify-content: center; } } } }

  @media all and (max-width: 340px) {
    input {
      width: 288px; } } }

.loader-add-teams {
  height: 70vh; }

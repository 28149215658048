@import "../../../style/index.sass";

.fourth-tab {
  & > p {
    margin: 0 0 40px 0;
    padding: 0;
    color: $color-ship-gray;
    font-size: 20px; }

  .input-block {
    .promoter-name {
      box-sizing: border-box;
      width: 451px;
      border: 1px solid $color-gray;
      border-radius: 2px;
      padding: 16px 15px;
      color: $color-silver-chalice;
      line-height: 18px;
      cursor: default; } }


  .ReactTable {
    margin-top: 40px;

    .rt-tbody {
      max-height: 188px;
      overflow: hidden; }

    .rt-tr-group {
      &:nth-child(2) > div {
        opacity: .5; }

      &:nth-child(3) > div {
        opacity: .1; } } } }



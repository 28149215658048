@import "../../style/index";

.canvas-zoom {
    pointer-events: auto;
    display: flex;
    gap: 10px;
    z-index: 15;
    align-items: center;
    p {
        margin: 0;
        height: 40px;
        padding: 0 10px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        border: 1px solid var(--primary-color); }
    button {
        cursor: pointer;
        background-color: transparent;
        border: 1px solid var(--primary-color);
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px; } }

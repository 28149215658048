.terms {
  text-align: left;
  &-title,
  .sub-title {
    font-size: 20px;
    text-align: center;
    margin-bottom: 20px;
  }
  & a {
    display: inline;
    color: rgb(17, 85, 204);
  }
  & h2 {
    font-weight: bold;
    margin-top: 16px;
    margin-bottom: 16px;
    font-size: 20px;
  }
  & p {
    margin-bottom: 16px;
    font-size: 16px;
  }
  .sub-title {
    text-align: left;
  }
  .terms-list {
    font-size: 16px;
    list-style: disc;
    padding-left: 40px;
    a {
      display: inline-block;
      color: blue;
      text-decoration: none;
      margin: 0;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

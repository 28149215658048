@import "../../../style/variables";

.input-validation {
    margin-bottom: 1px !important;
    &.hide-error {
        margin-bottom: 25.5px !important; } }

.input-validation-error {
    color: var(--reject-button-color);
    font-size: 14px;
    margin-left: 2px;
    margin-bottom: 3px;
    &.hide-error {
        display: none; } }

@import "../../style/index";

.events {
  height: calc(100vh);
  display: flex;
  flex-direction: column;

  .checkbox {
    margin-bottom: 0;

    label {
      width: max-content; }

    .loader {
      height: calc(100vh - 398px);
      margin-top: 20px; } }

  .page-title {
    margin: 36px 0; }

  @include respond-to('small-max') {
    margin-bottom: 86px;

    .page-title {
      width: 100%;

      position: fixed;
      top: 0;
      z-index: 2;
      background-color: $color-light;

      text-align: center;
      font-size: 18px;
      font-weight: bold;
      display: block; } }

  &__search {
    width: 418px;
    margin-bottom: 30px;

    @include respond-to('small-max') {
      width: -moz-available;
      width: -webkit-fill-available;
      width: stretch; } }

  .ReactTable {
    height: calc(100vh - 340px);
    margin-top: 20px;

    .rt-thead {
      padding: 0 30px;

      .rt-tr .rt-th {
        display: flex;
        align-items: center; } }

    .rt-tbody {
      padding: 0;

      .rt-tr-group {
        cursor: pointer;
        padding: 0 20px;
        transition: all 0.2s linear;

        display: block;

        .rt-td {
          padding: 20px 10px; }
        .rt-tr {
          display: flex; }

        &:hover {
          background-color: var(--secondary-light-color); } } }

    @include respond-to('small-max') {
      display: none; } }

  .payment-status {
    display: flex;
    gap: 3px;
    cursor: pointer;
    align-items: center; }

  .page-footer {
    margin-top: auto;

    @include respond-to('small-max') {
      width: 100%;
      padding: 20px 0;

      position: fixed;
      bottom: 0;
      left: 0;

      background-color: $color-light; }

    @media (max-width: 767px) and (orientation: landscape) {
      width: 100%;
      padding: 20px 0;

      position: fixed;
      bottom: 0;
      left: 0;

      background-color: $color-light; }

    .btn-primary {
      padding: 10px 30px;

      @include respond-to('small-max') {
        margin: 0 auto; }

      @media (max-width: 767px) and (orientation: landscape) {
        margin: 0 auto; } } }

  .events-mobiile-table {
    margin-top: 20px;

    display: none;

    @include respond-to('small-max') {
      display: block;
      margin-bottom: 84px; }

    @media (max-width: 767px) and (orientation: landscape) {
      display: block; }

    .events-mobiile-table__row {
      padding-top: 10px;
      padding-bottom: 2px;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      border-top: 1px solid $color-seashell;
      border-bottom: 1px solid $color-seashell;

      .events-mobiile-table__right-col {
        text-align: right;
        width: 150px;
        @media (max-width: 376px) {
          width: 125px; } }
      .event-name {
        margin-bottom: 10px;

        letter-spacing: -0.44px;
        font-family: var(--app-font-family);
        font-weight: 600;
        font-size: 16px;
        color: $color-ship-gray;
        @media (max-width: 426px) {
          width: 200px;
          text-overflow: ellipsis;
          overflow: hidden; }
        @media (max-width: 376px) {
          width: 165px; } }
      .event-tickets {
        @extend .event-name;

        font-weight: normal;
        color: $color-silver-chalice;
        @media (max-width: 426px) {
          width: 150px; }
        @media (max-width: 376px) {
          width: 125px; } }
      .event-date {
        letter-spacing: 1.05px;
        font-family: var(--app-font-family);
        font-size: 12px;
        color: $color-ship-gray; } } }

  &__empty-state {
    height: calc(100vh - 398px);

    display: flex;
    flex-direction: column;
    justify-content: center;

    text-align: center;

    @media (max-width: 767px) and (orientation: portrait) {
      margin-top: 5rem; }

    @media (max-width: 767px) and (orientation: landscape) {
      height: auto !important;
      margin-bottom: 84px; }

    .page-title {
      margin-bottom: 40px; }

    img {
      margin: 0 auto;
      width: 400px;
      height: 169px;
      @media (max-width: 767px) {
        width: 280px;
        height: auto; } }

    &__hint {
      margin-top: 30px;

      line-height: 30px;
      letter-spacing: 0.65px;
      font-family: var(--app-font-family);
      font-size: 18px;
      color: $color-dark; } } }

.nonexistent-event-modal p {
  font-size: 20px;
  margin: 0; }

@import "../../../../../style/index";

.management {
  padding-bottom: 30px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  &__loader {
    position: relative;
    flex-grow: 1; }

  &__searchPanel {
    display: flex;
    gap: 30px;
    margin-bottom: 20px;
    align-items: center;

    button {
      margin-left: auto;
      padding: 10px 30px;
      flex-shrink: 0; } }

  button {
    svg {
      display: initial; } }

  .searchPanel {
    &__select {
      width: 280px; }

    &__search {
      width: 450px; }

    &__search-small {
      width: 50px; }

    &__clear-wrapper {
      border: 1px solid $color-seashell;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center; }

    &__clear {
      margin: 0;
      padding: 5px;
      width: 40px;
      height: 40px;
      background: transparent;
      border: none;
      cursor: pointer; }

    &__btn_minimized {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 45px;
        height: 45px;
        padding: 0;
        margin: 0; }

    .minimized {
      svg {
          transform: rotate(180deg); } } }

  &__table-wrapper {
    overflow: hidden;
    padding: 5px; }


  .buttons-block {
        display: flex;
        gap: 14px;
        padding: 0px 24px;

        &.options {
            padding: 7px 24px; }

        .IconButton {
            height: 35px;
            width: 35px;
            border-radius: 5px;
            padding: 0;
            font-size: 12px;

            .IconButton {
              height: 33px;
              width: 33px;
              &.primary {
                border: none; } }

            &.primary {
                border: 1px solid var(--primary-color);
                background-color: $color-light; } } }

  .ReactTable {
    height: 100%;

    .rt-tbody {
      .rt-tr-group .rt-td {
        padding: 20px 5px;
        overflow: hidden;
        text-overflow: ellipsis;

        .status-label {
          color: $color-ship-gray;
          border-radius: 40px;
          width: 100px;
          height: 28px;
          display: flex;
          align-items: center;
          justify-content: center;

          &--1 {
            background-color: $color-minty-green; }
          &--2 {
            background-color: $color-creamy-vanilla; }
          &--3 {
            background-color: $color-lavender-bliss; }
          &--4 {
            background-color: $color-light-blue; }
          &--5 {
            background-color: $color-rosewater-pink; } } } }

    .rt-thead.-header .rt-tr .rt-th {
      padding: 20px 5px; } }

  .buttons-block {
    padding: 0;

    .IconButton.primary:disabled {
      opacity: 0.4;
      cursor: default;
      svg {
        background-color: transparent;
        cursor: default;
        &:hover {
          box-shadow: none; } } } } }

.swal2-popup .swal2-input {
  height: 46px; }

@import "../../../style/index";

.seasonpass-pers-info {
    color: $color-text-dark;
    font-family: var(--app-font-family);
    max-width: 1090px;

    input {
        &:hover {
            border-color: $color-partizan-primary; }
        &:focus {
            border-color: $color-partizan-primary; } }
    &__tick-list {
        margin-top: 40px;
        width: 100%; }

    &__tick-total {
        margin-top: 40px;
        width: 100%;
        font-size: 18px;
        font-weight: bold; }

    .Upload {
        width: 400px;
        height: 241px; }

    .upload-img {
        width: 378px;
        height: 220px;
        padding: 10px;
        border: 1px solid $color-seashell;
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        @include respond-to('small-max') {
            width: 100%; }
        img {
            width: 378px;
            height: 220px;
            border-radius: 10px;
            @include respond-to('small-max') {
                width: 100%;
                height: 100%; } }

        .IconButton {
            height: 35px;
            width: 35px;
            padding: 7px;
            position: absolute;
            top: 10px;
            right: 10px;

            .IconButton {
              top: 0;
              left: 0; }

            &:disabled {
                background-color: $color-light-gray; } } }

    &__header {
        &-logo-wrapper {
            border-top-right-radius: 12px;
            border-top-left-radius: 12px;
            border-left: 1px solid $color-seashell;
            border-right: 1px solid $color-seashell;
            border-top: 1px solid $color-seashell;
            padding: 120px 0;
            background-size: cover;
            background-image: url("/images/partizan-sp-login-email-header.jpeg");
            // background-color: var(--primary-light-color)
            display: flex;
            align-items: center;
            justify-content: center;
            @include respond-to('small-max') {
                padding: 80px 0; } } }

    &__logo {
        // padding: 24px 0
        // @include respond-to('small-max')
        //     padding: 12px 0
        h2 {
            color: #0C0019;
            text-transform: uppercase;
            font-weight: 500;
            margin: 0 0 0 14px;
            font-size: 28px;
            @include respond-to('small-max') {
                font-size: 21px; } } }

    &__main-block {
        border-left: 1px solid $color-seashell;
        border-right: 1px solid $color-seashell;
        border-bottom: 1px solid $color-seashell;
        border-bottom-right-radius: 12px;
        border-bottom-left-radius: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px 90px 56px 90px;
        @include respond-to('small-max') {
            padding: 0 0 28px; }

        .first-title {
            margin-top: 28px;
            color: $color-text-dark;
            text-align: center;
            @include respond-to('small-max') {
                width: 100%;
                font-size: 21px; } } }

    &__notification {
        background-color: $color-partizan-primary;
        border-bottom-right-radius: 12px;
        border-bottom-left-radius: 12px;
        width: 417px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        color: $color-light; }

    &__row {
        position: relative;
        display: flex;
        gap: 32px;
        width: 100%;
        @include respond-to('small-max') {
            display: block; } }

    &__column {
        flex: 1;
        display: flex;
        @include respond-to('small-max') {
            display: block; } }

    &__block {
        margin-bottom: 20px;
        flex: 1;
        label {
            display: block;
            color: $color-silver-chalice;
            font-family: var(--app-font-family);
            font-size: 16px;
            letter-spacing: -0.44px;
            line-height: 22px;
            margin-bottom: 10px; }

        input {
            width: 100%;
            background: white;
            height: 46px;
            box-sizing: border-box;
            border-radius: 4px;
            @include respond-to('small-max') {
                width: 100%; }

            &.email-disable {
                color: $color-silver-chalice; } }

        .react-tel-input {
          .custom-phone-number-input {
            background-color: $color-light;
            height: 46px;
            font-size: 16px;
            font-family: var(--app-font-family);
            border: 1px solid $color-seashell;
            width: calc(100% - 40px);
            margin-left: 40px;
            padding: 10px 14px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;

            &:hover {
              border-color: $color-partizan-primary; }
            &:focus {
              border-color: $color-partizan-primary; } }

          .custom-phone-number-button {
            background-color: $color-light;
            border: 1px solid $color-seashell;
            border-right: none;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0; }

          .custom-phone-number-dropdown {
            padding-bottom: 5px;
            font-size: 16px;
            font-family: var(--app-font-family);
            box-shadow: none;
            border: 1px solid $color-seashell;
            border-radius: 5px;
            user-select: none;

            .country {
              padding-left: 17px; }

            .country.highlight {
              background-color: $color-partizan-primary;
              color: $color-light;

              span {
                color: $color-light; } } }

          .custom-phone-number-search {
            width: calc(100% - 15px); } } }




    &__points-total {
        font-size: 16px;
        font-weight: 500; }

    .ReactTable {
        max-height: 500px;
        margin-top: 40px;
        box-shadow: none;
        border-radius: 5px;
        border: 1px solid $color-seashell !important;
        width: 100%;
        .rt-th, .rt-td {
            box-sizing: border-box;
            padding-right: 20px !important;
            padding-left: 20px !important; }
        .rt-thead {
            padding: 0; }
        .rt-tbody {
            padding: 0; }

        .rt-tfoot {
            box-shadow: none;
            .rt-td {
                padding: 20px;
                border: none;
                @include respond-to('small-max') {
                    &:not(:first-of-type) {
                        display: none; } } } } }

    &__buy-tickets-modal {
      position: relative;

      .partizan-open-selling__selected-tickets {
        height: 368px; } }

    &__buy-tickets-terms {
      width: max-content;
      position: absolute;
      right: 140px;
      bottom: 130px;

      @include respond-to('small-max') {
        right: auto;
        left: 0; } }

    .save-changes {
        margin-top: 40px;
        width: 180px; }

    .buy-all {
        margin-top: 48px;
        padding-left: 32px;
        padding-right: 32px; }

    .partizan-open-selling__select-section-ticket-count button svg path {
        stroke: $color-partizan-primary; }

    .btn-primary {
        background-color: $color-partizan-primary;
        &:disabled {
            background-color: transparent;
            border: 1px solid $color-silver-chalice;
            color: $color-silver-chalice;
            line-height: 22px;
            border-radius: 8px;
            &:hover {
                cursor: not-allowed;
                box-shadow: none; } }
        &:hover {
            -webkit-box-shadow: 0px 0px 8px 0px;
            -moz-box-shadow: 0px 0px 8px 0px $color-partizan-primary;
            box-shadow: 0px 0px 8px 0px $color-partizan-primary; } }

    &__list {
        display: flex;
        flex-wrap: wrap;
        gap: 32px;
        @include respond-to('small-max') {
            width: 100%; } } }

.swal2-modal .swal2-content input {
  box-sizing: border-box; }

.swal2-header .swal2-title {
  color: $color-text-dark; }

.swal2-modal .swal2-content {
  .seasonpass__select__control.seasonpass__select__control {
    height: 46px;
    padding: 0; }

  .IconButton {
    padding: 0; }

  .css-1hwfws3 {
    height: 46px;
    padding: 0 8px; }

  .css-1g6gooi {
    padding-top: 0;
    padding-bottom: 0; }

  .buy-sp-modal__vat-number {
    &:hover {
        border-color: $color-partizan-primary; }
    &:focus {
        border-color: $color-partizan-primary; } } }

.buy-sp-modal {
  &__vat-number {
    margin: 15px 0; }

  &__vat-number, &__select {
    width: 50%; }

  .swal2-popup .swal2-actions .swal2-confirm {
    &:hover {
      -webkit-box-shadow: 0px 0px 8px 0px;
      -moz-box-shadow: 0px 0px 8px 0px $color-partizan-primary;
      box-shadow: 0px 0px 8px 0px $color-partizan-primary; } } }

.buy-membership-modal {
  &__radio-wrapper {
    display: flex;
    gap: 40px;
    justify-content: center;
    margin-bottom: 30px; } }

.fill-company-data-modal {
  &__title {
    font-size: 18px;
    text-align: center;
    font-weight: 700;
    margin-bottom: 40px; }

  label {
    text-align: left; }

  .seasonpass-pers-info__block {
    margin-bottom: 30px; } }


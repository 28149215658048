@import '../../style/index';

.not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: var(--app-font-family);
  height: 100vh;
  padding: 40px 0;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  z-index: 2;

  @media all and (max-width: 768px) {
    padding: 40px 30px;
    height: auto; }

  h2 {
    color: var(--primary-color);
    font-size: 50px;
    font-weight: bold;
    margin: 0;
    text-align: center;

    @media all and (max-width: 768px) {
      font-size: 20px; } }

  img {
    margin: 80px 0 50px 0;
    max-height: 297px;
    height: 50%;
    max-width: 400px;
    width: 100%;

    @media all and (max-width: 768px) {
      height: auto;
      margin-top: 30px; } }

  button {
    width: 170px;
    padding: 10px 0; } }

@media all and (max-width: 768px) {
  body, html {
    padding: 0; } }

@import "../../../style/index";

.add-event {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 80vh;
  height: calc(100vh - 104px);

  .page-title {
    margin: 36px 0;

    @media all and (max-width: 767px) {
      font-size: 18px;
      font-weight: bold;
      display: block;
      margin: 0;
      width: 100%;
      padding: 10px;
      position: fixed;
      top: 0;
      z-index: 2;
      background-color: $color-light;
      text-align: center; } }

  button {
    padding: 10px 30px; }

  &__row {
    position: relative;
    display: flex;
    flex-wrap: wrap;

    & > div {
      &:nth-child(1),
      &:nth-child(2) {
        margin-right: 115px; } }

    &--width-divider {
      border-bottom: 2px solid $color-seashell;
      padding-bottom: 20px;
      margin-bottom: 40px; } }

  &__column {
    display: flex;

    & > div:nth-child(2) {
      margin-left: 157px;
      @media all and (min-width: 768px) and (max-width: 838px) {
        justify-content: flex-start;
        margin-left: 86px; } } }
  &__block {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    width: 306px;

    label {
      color: $color-silver-chalice;
      font-family: var(--app-font-family);
      font-size: 16px;
      letter-spacing: -0.44px;
      line-height: 22px;
      margin-bottom: 10px; }

    input {
      width: 306px;
      background: white;
      height: 46px;
      box-sizing: border-box;
      border-radius: 4px; }

    .custom-select {
      width: 306px; }

    span {
      color: $color-ship-gray;
      font-family: var(--app-font-family);
      font-size: 16px;
      letter-spacing: -0.44px;
      line-height: 22px;
      display: flex;
      align-items: center; } }
  &__block.row {
    flex-direction: row; }

  &__checkboxes-item {
    width: 306px;
    flex-grow: 1;
    min-height: 70px;
    justify-content: flex-end; }

  & .react-datepicker-popper {
    z-index: 10; }

  &__select {
    // z-index: 5
    input {
      height: auto; }

    &__control {
      width: 306px;
      border: 1px solid $color-seashell!important;
      box-shadow: none!important;
      border-radius: 4px;
      font-family: var(--app-font-family); }

    &__indicator-separator {
      visibility: hidden; }

    &__menu {
      font-family: var(--app-font-family); } }

  @media all and (max-width: 767px) {
    .page-footer {
      justify-content: center;

      button {
        min-height: 44px; } }

    &__row {
      align-items: left;
      flex-direction: column; }
    &__column {
      margin: 0!important;
      display: flex;
      justify-content: space-between;
      max-width: 306px;
      width: 100%;

      & > div:nth-child(2) {
        margin-left: 0; } }

    .react-datepicker {
      max-width: 290px;
      &__time {}
      &-container {
        width: 45px; }
      &-list-item {
        width: 45px;
        box-sizing: border-box;
        padding: 10px 2px!important; } } }

  @media all and (max-width: 340px) {

    &__column {
      max-width: 288px;
      input {
        width: 288px; } }

    &__select {
      &__control {
        width: 288px; } } }

  @media all and (max-width: 380px) {
    .react-datepicker-popper {
      transform: scale(0.9); } } }

@media all and (min-width: 768px) and (max-width: 989px) {
  .all-and-scanned {
    display: flex;
    & .add-event__column {
      min-width: 306px;
      justify-content: space-between;
      & .add-event__block:nth-child(2) {
        margin: 0; } }

    .add-event__row {
      flex-direction: column; }

    .add-event__row.table {
      flex-direction: row;
      & > div:first-child() {
        margin-right: 50px; } }
    .add-event {
      & .page-footer {
        justify-content: center; } } } }

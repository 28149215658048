@import "../../../../../style/index";


.info {
  .Dropzone {
    border-radius: 12px;
    border: 2px solid $color-seashell;
    padding: 0;
    margin-bottom: 20px;
    overflow: hidden;
    height: 300px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    align-items: center;
    justify-content: center; }

  .empty-block {
    display: flex;
    flex-direction: column;
    padding: 70px 0;
    img {
      margin: 0 auto;
      width: 400px;
      height: 169px;
      @media (max-width: 767px) {
        width: 280px;
        height: auto; } }
    &__text {
      text-align: center;
      color: $color-ship-gray;
      font-family: var(--app-font-family);
      font-weight: 400;
      font-size: 20px;
      line-height: 23px;
      margin-bottom: 0px;
      font-weight: bold;
      position: relative; } }

  .link-stats {
    display: flex;
    gap: 24px;
    justify-content: end;
    padding-right: 48px;
    &:hover {
      cursor: pointer; }

    svg {
      width: 14px;
      margin-right: 8px;
      vertical-align: middle; } }

  .page-footer {
    align-items: center;

    button {
      height: 45px;
      min-width: 150px;
      margin-left: 19px;
      &:nth-child(1) {
        margin-left: 0; } }

    &__checkboxes {
      display: flex;
      align-items: center;
      margin-left: 0;
      margin-right: auto;
      gap: 40px; }

    .checkbox {
      margin-bottom: 0; } } }



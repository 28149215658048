@import "../../style/index.sass";

.refund-tickets-modal {
    background: $color-seashell;
    padding: 32px 0 32px 32px;

    .swal2-title {
        margin-bottom: 24px; }

    .swal2-content {
        max-height: 570px;
        padding-right: 32px;
        overflow-x: hidden;
        overflow-y: auto; }

    .swal2-label {
        font-size: 16px;
        color: $color-ship-gray; }

    .swal2-actions {
        display: flex;

        .swal2-confirm[disabled] {
            background-color: $color-silver-chalice !important;
            box-shadow: none; } }

    .swal2-actions:not(.swal2-loading) {
        .swal2-styled[disabled] {
            opacity: 1; } }

    .swal2-cancel {
        background-color: $color-gray; }

    .checkbox-label {
        display: flex;
        user-select: none;
        align-items: center;
        margin-bottom: 15px;

        .checkbox {
          margin-bottom: 0; }

        span {
            margin-top: 2px; } }

    .seats-list {
        text-align: left;
        font-size: 14px;
        margin-bottom: 20px;

        .title {
            font-size: 16px;
            color: $color-ship-gray; } }

    .label-block {
        text-align: left;
        margin-bottom: 24px;
        display: flex;
        flex-direction: column;
        gap: 8px; }

    .total {
      justify-self: left;
      font-size: 16px;
      margin-bottom: 30px; } }

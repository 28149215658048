.apple-wallet {
  width: 100%;
  height: 85%;
  position: relative;
  color: #fff;
  display: flex;
  flex-direction: column;

  &__background {
    background-size: cover;
    background-position: center;
    filter: blur(3px);
    position: absolute;
    inset: 0; }

  &__main, &__title {
    position: relative;
    z-index: 2; }

  &__title {
    font-weight: 500;
    padding: 15px 5px; }

  &__main {
    padding: 10px;
    text-transform: uppercase;
    flex-grow: 1; }

  &__label {
    font-size: 10px; }

  &__data {
    margin: 0;
    font-size: 14px;
    line-height: 100%; }

  &__info-general {
    display: grid;
    grid-template-columns: 1fr 60px;
    gap: 10px;
    margin-bottom: 10px; }

  &__owner-gate {
    display: flex;
    justify-content: space-between;
    margin-top: 5px; }

  &__info-seat {
    display: flex;
    justify-content: space-between; }

  &__qr, &__picture {
    position: relative;
    overflow: hidden;

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; } }

  &__qr {
    width: 100px;
    height: 100px;
    margin: 20px auto; } }

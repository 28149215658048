@import "../../style/index.sass";

.counter {
  font-family: var(--app-font-family);
  .counter-content {
    display: flex;
    align-items: center;
    width: 100px;
    margin-top: 10px;

    input {
      width: 58px;
      text-align: center; }

    span {
      height: 28px;
      width: auto;
      color: $color-ship-gray;
      font-size: 18px;
      line-height: 28px;
      text-align: center;
      display: inline-block; }

    div.counter-btn {
      position: relative;
      border: none;
      width: 30px;
      height: 10px;
      cursor: pointer;
      background: none;

      &::after {
        content: '';
        position: absolute; }

      &.minus::after {
        border: 5px solid transparent;
        border-top: 10px solid $color-ship-gray;
        left: 0;
        top: 0; }

      &.plus::after {
        border: 5px solid transparent;
        border-bottom: 10px solid $color-ship-gray;
        right: 0;
        top: -4px; } } } }

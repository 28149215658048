@import "../../../../../style/index";

.sell {
    margin-top: 0;

    button {
      svg {
        display: initial; } }

    &__info-modal-link {
        margin: 20px 0 0 0;
        color: var(--primary-color); }

    .stepper__reservation-wrapper {
      margin: 20px 0;
      display: flex;
      justify-content: space-between; }

    .reservation-options {
        width: 400px;
        display: grid;
        gap: 1rem;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;

        .partizan-open-selling__select {
            grid-column: 1 / span 2; } }

    .report-button {
      padding: 0 20px; }

    .partizan-open-selling {
      &__stepper-header {
        background-color: $color-gray; }

      &__payment-method {
        margin-top: 30px;
        width: 320px;
        padding: 0; }

      &__logo {
        img {
          width: 46px;
          height: 46px; } } } }



@import "../../style/index.sass";

.ticket-action {
    font-family: var(--app-font-family);
    height: 100vh;
    display: flex;
    flex-direction: column;

    .page-title {
        &:first-child {
            margin: 0 0 30px 0; } }

    input {
        max-width: 300px;
        margin-bottom: 20px; }
    .custom-select {
        max-width: 300px;
        margin-bottom: 20px; }
    & > .seats-io-link {
        color: var(--primary-color);
        font-size: 18px;
        line-height: 24px;
        text-decoration: underline;
        padding: 0;
        margin: 0;

        & ~ .seats-io-link {
            margin: 0 0 23px 0; } }

    &__download {

        & > button {
            height: auto; }

        button {
            color: var(--primary-color);
            font-size: 18px;
            line-height: 24px;
            text-decoration: underline;
            background: none;
            text-align: left;
            border: none;
            cursor: pointer;
            padding: 0; } }

    &__submit {
        align-self: flex-end;
        width: 150px;
        margin: 30px 0; }

    &__de-restrict {
        margin: 30px 0; }

    &__de-restrict {
        align-self: flex-end;
        width: 186px; }

    &__choose-file {
        position: relative;
        max-width: 641px;

        & > p {
            color: $color-ship-gray;
            font-size: 20px;
            font-weight: bold;
            line-height: 27px;
            margin-top: 0; } }

    .btn-secondary {
        padding: 0 24px; }

    &__upload {
        flex: 1; } }

.generate-pdfs {
    min-height: 100vh;
    height: auto;

    &__action {
        display: flex;
        > *:not(:last-child) {
            margin-right: 20px; } }

    .ReactTable {
        margin-bottom: 30px; } }

.event-action-selector {
    margin-top: 20px;
    margin-bottom: 80px; }

.download-actions-container {
    display: flex;
    flex-direction: row;
    column-gap: 40px;
    margin-bottom: 20px; }

.download-action {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 12px; }

.bottom-buttons-container {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between; }

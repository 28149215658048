@import "../../style/index";

.nav-tabs {
  padding-top: 22px;
  overflow: hidden;

  @media all and (max-width: 767px) {
    padding-top: 0; }

  &__container {
    display: flex;
    justify-content: space-around;
    overflow: hidden;

    &:hover {
      overflow: auto; }

    &--small {
      justify-content: flex-start; } }

  &__item {
    padding: 17px 25px;
    margin: 0;

    text-decoration: none;
    white-space: nowrap;
    font-family: var(--app-font-family);
    font-size: 16px;
    font-weight: 700;
    color: $color-dark;

    &:hover {
      color: var(--secondary-color); }

    &.active {
      border-bottom: 2px solid var(--secondary-color);
      color: var(--secondary-color); }

    &.disabled {
      color: $color-silver-chalice;
      cursor: default; } } }

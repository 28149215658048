@import "../../../style/index";

.btn-burger {
    width: 24px;
    height: 14px;

    &:hover {
        cursor: pointer; }

    div {
        width: 24px;
        height: 2px;
        background-color: black;

        &:not(:last-child) {
            margin-bottom: 4px; } } }

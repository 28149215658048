@import "../../../../../../style/index";

.google-wallet {
  width: 100%;
  height: 100%;
  background-color: #ffce00;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 15px;
    border-bottom: 2px solid #f1b74c; }

  &__icon-container, &__qr-code-container, &__footer {
    position: relative;
    overflow: hidden;

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; } }

  &__icon-container {
    width: 40px;
    height: 40px; }

  &__main {
    padding: 15px; }

  &__event-name {
    font-size: 18px;
    margin-bottom: 20px; }

  &__seat-info {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 5px;
    justify-content: space-between;

    & > div {
      &:nth-child(2) {
        justify-items: center; }
      &:nth-child(3) {
        justify-items: flex-end; } } }

  &__seat-label {
    font-size: 12px; }

  &__seat-data {
    margin: 0;
    font-size: 14px;
    text-transform: uppercase; }

  &__qr-code-container {
    width: 150px;
    height: 150px;
    margin: 20px auto 10px; }

  &__ticket-name {
    text-align: center; }

  &__footer {
    height: 75px; } }


@import '../../../../style/index';

.balances {
    display: grid;
    grid-template-columns: repeat(4, 12rem);
    gap: 2rem;

    .download-report {
        align-self: center; } }

.balance-label {
    font-family: var(--app-font-family);
    color: $color-ship-gray;
    background-color: var(--primary-semi-light-color);
    padding: 0.5rem 1rem;
    border-radius: 10px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;

    &.available {
        background-color: $color-iron; }

    &.pending {
        background-color: $color-rosewater-pink; }

    &.refunded {
        background-color: $color-minty-green; }

    p {
        margin: 0px;
        padding: 0px;
        text-align: center; } }

.clearing-table-title {
    font-size: 1rem;
    font-family: var(--app-font-family);
    color: $color-ship-gray; }

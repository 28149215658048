@import "../../style/index";

.seasonpass {
  font-family: var(--app-font-family);

  @media(min-width: 768px) {
    margin: 40px auto; }

  &__total {
    @media(max-width: 768px) {
      margin-bottom: 20px; }
    @media (max-width: 380px) {
      font-size: 14px; }
    > *:not(:last-child) {
      margin-bottom: 10px; } }

  &__wrapper {
    border-radius: 12px;
    padding-bottom: 68px;
    border: 1px solid $color-seashell; }

  &__header {
    &-logo-wrapper {
      border-top-right-radius: 12px;
      border-top-left-radius: 12px;
      padding: 10px 0;
      background-color: #F2F4FE;
      display: flex;
      align-items: center;
      justify-content: center; }

    &-logo {}

    &-title {
      margin: 0;
      padding: 30px;
      color: $color-ship-gray;
      font-size: 36px;
      text-align: center;
      @media(max-width: 768px) {
        font-size: 28px; } } }

  &__form {
    padding: 0 48px;
    @media(max-width: 768px) {
      padding: 0 28px; } }

  &__select {
    text-align: left;
    &__single-value {
      &--is-disabled.seasonpass__select__single-value--is-disabled {
        color: $color-silver-chalice; } }
    &__control.seasonpass__select__control {
      border-radius: 2px;
      border-color: $color-seashell;
      padding: 2px 0;
      &:hover {
        border-color: $color-seashell; }
      &--is-disabled.seasonpass__select__control--is-disabled {
        background-color: #F8F9FA; } }
    &__indicator-separator.seasonpass__select__indicator-separator {
      visibility: hidden; } }

  &__field {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    input:disabled {
      background-color: #F8F9FA;
      color: $color-silver-chalice; }

    &-text {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      &-label {
        font-weight: 600;
        color: $color-ship-gray;
        font-size: 16px;
        @media(max-width: 768px) {
          font-size: 14px; }
        @media (max-width: 380px) {
          font-size: 12px; } }
      &-required {
        font-weight: 400;
        color: $color-light-gray;
        font-size: 13px;
        @media (max-width: 380px) {
          font-size: 11px; } } }

    &-input {
      height: 46px;
      box-sizing: border-box; } }

  &__dropzone {
    margin-bottom: 20px;
    &-hero {
      border-radius: 8px;
      border: 1px dashed $color-gray;
      overflow: hidden;
      height: 202px; }
    &-image {
      width: 100%;
      height: 120px;
      display: block;
      border: 1px solid $color-seashell;
      border-radius: 2px;
      &-drop {
        position: absolute;
        right: -10px;
        top: 30px;
        width: 26px;
        height: 26px;
        background: #6071B5;
        border-radius: 50%;
        cursor: pointer;
        border: none;
        &:before, &:after {
          position: absolute;
          left: 12px;
          top: 5px;
          content: ' ';
          height: 15px;
          width: 2px;
          border-radius: 4px;
          background-color: $color-light; }
        &:before {
          transform: rotate(45deg); }
        &:after {
          transform: rotate(-45deg); } }
      &-wrapper {
        padding: 40px 0;
        max-width: 220px;
        margin: 0 auto;
        position: relative;
        @media(max-width: 350px) {
          max-width: 200px; } } }
    &-format-text {
      @media(max-width: 768px) {
        margin-left: 10px;
        font-size: 12px; } }
    .Dropzone {
      button {
        background-color: transparent;
        border: 1px solid #6071B5;
        color: #6071B5;
        font-size: 18px; } }
    .seasonpass__field-text:last-child {
      margin-bottom: 0px;
      margin-top: 10px; }
    p {
      text-align: center;
      color: $color-light-gray;
      font-size: 16px; } }

  &__btn.seasonpass__btn {
    width: 150px;
    background-color: #6071B5;
    &:hover {
      -webkit-box-shadow: 0px 0px 8px 0px #6071B5;
      -moz-box-shadow: 0px 0px 8px 0px #6071B5;
      box-shadow: 0px 0px 8px 0px #6071B5; }
    &_request-changes {
      width: 180px;
      display: block;
      @media(max-width: 768px) {
        margin: 0 auto; } } }

  &__grouped-fields {
    display: flex;
    @media(max-width: 768px) {
      flex-direction: column; }

    > * {
      flex: 1 0;
      margin-right: 12px;
      @media(max-width: 768px) {
        margin-right: 0; } }
    > *:last-child {
      margin-right: 0; }

    &_selects {
      > * {
        flex: 2 0; }
      > *:last-child {
        flex: 1 0; } } }

  .checkbox {
    input:checked + label:after {
      border-color: #6071B5; }
    label {
      @media (max-width: 380px) {
        font-size: 12px; }
      &:before {
        border-color: #6071B5; } } }

  &__result-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 768px) {
      flex-direction: column-reverse; }
    & > div:first-child {
      display: flex;
      flex-direction: column;
      @media (max-width: 560px) {
        align-items: center; } } }

  &__terms-and-conditions {
    .checkbox {
      margin-bottom: 0;
      label {
        width: auto;
        @media (max-width: 380px) {
          font-size: 14px; } } } }

  &__total-text {
    margin-right: 5px;
    font-weight: 600; }

  &__modal {
    .swal2-actions {
      .swal2-confirm.swal2-confirm {
        background-color: $color-partizan-primary;
        &:hover, &:focus {
          -webkit-box-shadow: 0px 0px 8px 0px $color-partizan-primary;
          -moz-box-shadow: 0px 0px 8px 0px $color-partizan-primary;
          box-shadow: 0px 0px 8px 0px $color-partizan-primary; } } }

    &_info {
      border-radius: 12px;
      border: 1px solid $color-seashell;
      overflow: hidden;
      .swal2-header {
        display: none; } } }

  &__bank-logos {
    margin-top: 32px;
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
    align-items: center; }

  &__seats-map {
    max-width: 400px;
    display: block;
    margin: 0 auto;
    border: 1px solid $color-seashell;
    border-radius: 8px;
    background-color: transparent;
    cursor: pointer;
    margin-bottom: 20px;
    img {
      max-width: 100%;
      max-height: 100%; } } }
